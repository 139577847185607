export const MAP_PATH = process.env?.REACT_APP_TILE_PATH
  ? process.env.REACT_APP_TILE_PATH
  : 'https://tile.interactivemap.ru/tile/{z}/{x}/{y}.png';

export enum Map {
  CUSTOM_WMS_LAYER = 'https://dev.map.urc.dev.kraud.ru:5443/geoserver/cite/wms',
  MIN_ZOOM = 3,
  MAX_ZOOM = 15,
  FLY_TO_ZOOM_VALUE = 15,
  DEFAULT_ZOOM = 3.5,
  DEFAULT_LAT = 69,
  DEFAULT_LNG = 104,
  MINI_MAP_DEFAULT_ZOOM = 16,
  MINI_MAP_MIN_ZOOM = 5,
}

export const MAIN_MAP_STREET_LINE_PREFIX = 'street-line';
export const MAIN_MAP_ID = 'main-map';
export const PROJECT_MAP_ZOOM = 14;

export enum LineColors {
  type_1 = 'hsl(331, 94%, 60%)',
  type_2 = 'hsl(234, 90%, 55%)',
  type_3 = 'hsl(117, 43%, 44%)',
}
