import { FilterBottomPanelProps } from './filter-bottom-panel.types';
import styles from './filter-bottom-panel.module.scss';
import React from 'react';
import { Button } from '../../common';

export const FilterBottomPanel = ({onReset, isResetButtonActive = false } : FilterBottomPanelProps) => {
  return  (
    <div className={styles['filter-bottom-panel']}>
      <Button
        onClick={onReset}
        elementType={'button'}
        size="m"
        typeBtn={'secondary'}
        text={isResetButtonActive ? 'Очистить' : 'Выберите фильтры'}
        justifyContent={'center'}
        disabled={!isResetButtonActive}
      />
    </div>
  );
};