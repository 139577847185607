import classNames from 'classnames';
import { ICheckbox } from './checkbox.type';
import styles from './checkbox.module.scss';

export const Checkbox = ({
  name,
  id,
  onChange,
  className,
  value,
  checked,
}: ICheckbox) => {
  const containerClassnames = classNames(styles.checkbox__container, className);
  const checkboxClassnames = classNames(styles.checkbox, {
    [styles.checked]: checked,
  });
  const labelClassnames = classNames(styles.checkbox__label, {
    [styles.checkbox__label_checked]: checked,
  });

  return (
    <div className={containerClassnames}>
      <input
        name={name}
        id={id}
        className={checkboxClassnames}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label className={labelClassnames} htmlFor={id}>
        {name}
      </label>
    </div>
  );
};

export default Checkbox;
