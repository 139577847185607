import { Search } from './search';
import { SearchWrapperProps } from './search.types';
import { useAppSelector } from '../../state';
import { items } from '../../___prototype';
import { ObjectInterface } from '../../types';


export const SearchWrapper = ({ children }: SearchWrapperProps) => {
  //const { items } = useAppSelector((state) => state.objects);

  return (
    <Search items={items as unknown as ObjectInterface[]}/>
  );
};