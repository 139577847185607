import { useEffect } from 'react';
import { ProfileForm } from './profile-form';
import { ProfileHeader } from '../../components/profile-header';
import { useGetUserInfoMutation } from '../../state';
import { useDispatch } from 'react-redux';


export const UserInfoContent = () => {
  const dispatch = useDispatch();
  const [getUserInfo] = useGetUserInfoMutation();

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="profile-page-content">
      <div className="profile-page_bio">
        <div className="profile-page-header-container">
          <ProfileHeader />
        </div>
        {/*<ProfileForm/>*/}
      </div>
    </div>
  );
};
