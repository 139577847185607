export const SidebarObjectInfoHeading = {

  metrics: 'Метрики объекта: ',
  dates: 'Сроки',
  distance: 'Протяженность',
  square: 'Площадь благоустройства',

  works: 'Основные виды и объёмы работ:',

  possibility: 'Вместимость храма: ',
  employeeVisits: 'Количество сотрудников ПАО «Газпром»: ',
  necessaryInvest: 'Общий объем благотворительности: ',
  volumeWorks: 'Общий объём и перечень работ: ',
  distanceDistrictCenter: 'До административного здания филиала: ',
  owner: 'Инициатор: ',
  place: 'Местоположение: ',
  macroRegionValue: 'Макрорегион: ',
  programValue: 'Программа: ',
  typeOfWork: 'Тип строительства: ',
  realizationPeriod: 'Срок реализации: ',
  monitoringId: 'Id Мониторинг проекта: ',
  startOfBuilding: 'Старт работ: ',
  name: 'Имя: ',
  descriptionObject: 'Описание объекта :',
  scopeAndStagesOfWork: 'Объем и этапы работ:',
  budgetAllocation: 'Распределение бюджета:',
  dangerZone: 'Опасная зона: ',
  descriptionOfCharitableWorks: 'Описание благотворительных работ: ',
  realizationStatus: 'Статус реализации: ',
  direction: 'Направление: ',
};