import type { MiniViewToCenterButtonsProps } from './mini-view-buttons.types'

import { useMemo } from 'react'

import { Button } from '@components/from-sfera'
import { ButtonTypes } from '@/components/from-sfera/button/button.types'
import {  UISizes } from '@/types/mods.types'

import styles from './mini-view-buttons.module.scss'
import classnames from 'classnames'

export const MiniViewToCenterButton = ({
  isTablet = false,
  isMapFullScreen,
  onClick,
  className = '',
}: MiniViewToCenterButtonsProps) => {

  return useMemo(
    () => (
      <Button
        onClick={onClick}
        type={ButtonTypes.elevation}
        className={classnames(
          isMapFullScreen
            ? styles['center-btn-maximize']
            : styles['center-btn-minimize'],
          isTablet && styles.tables,
          className,
        )}
        iconLeft={<i className={'icon-aim icon icon-tiny'} />}
        size={UISizes.medium
        }
      />
    ),
    [isMapFullScreen, isTablet, onClick],
  )
}
