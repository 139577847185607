import { ITooltipProps } from './tooltip.types';
import styles from './tooltip.module.scss';
import 'react-popper-tooltip/dist/styles.css';
import { usePopperTooltip } from 'react-popper-tooltip';
import { TriggerType } from 'react-popper-tooltip';
import { PTag } from '../text';


type IPopperTooltipProps = {
  placement?: 'top' | 'left' | 'right' | 'bottom' | 'auto' | 'auto-start' | 'auto-end' | 'top-start'
  | 'top-end' | 'bottom-start' | 'bottom-end' | 'right-start' | 'right-end' | 'left-start' | 'left-end',
};


export const ToolTip = ({
  className = '',
  isVisible = false,
  toolTipMaxWidth = 472,
  children,
  content = '',
  placement = 'bottom-start',
  trigger = 'hover',
  offset = [0, 7],
  delayHide = 120,
  delayShow = 120,
  id,
}: ITooltipProps) => {

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: trigger as TriggerType,
    placement: placement as IPopperTooltipProps['placement'],
    offset: offset,
    delayHide: delayHide,
    delayShow: delayShow,
  },
  );

  return (
    <div className={styles.tooltip} id={id}>
      <div
        ref={setTriggerRef}
      >
        {children}
      </div>
      {visible && <div className={`${styles['tooltip-container']} tooltip-container`}
        ref = {setTooltipRef}
        { ...getTooltipProps({
        })}
      >
        {
          isVisible && (
            <PTag 
              x={3.5} 
              weight={1} 
              className={`${styles['tooltip-container-content']} tooltip-body`} 
              style={{ maxWidth: `${toolTipMaxWidth}px` }}
            >
              {content}
            </PTag>
          )
        }
      </div>}
    </div>
  );
};
