import { SpanTag, Button } from '@/components/common';
import { Timer } from '@/components';
import { Snackbar } from '../../snackbar';
import styles from '../../snackbar.module.scss';
import { SnackbarDefaultType } from '@components/snackbar/snackbar.types';

export const SnackbarDefault = ({
  maxTime,
  isMobile,
  withAction,
  timerSize = 's',
  timerTime = 5000,
  text = '',
  handleClick,
  actionButtonText,
}: SnackbarDefaultType) => {
  return (
    <Snackbar type={'default'} isMobile={isMobile} withAction={withAction}>
      <div className={styles['snackbar-content']}>
        <div className={styles['snackbar-action-container']}>
          {withAction && (
            <Timer
              maxTime={maxTime}
              trackWidth={2}
              trackColor={'transparent'}
              indicatorWidth={2}
              indicatorColor={'#0F9CFA'}
              indicatorCap={'round'}
              size={timerSize}
              timerTime={timerTime}
            />
          )}
          <SpanTag
            type="text"
            x={3.5}
            weight="1"
            className={styles['snackbar-content-text']}
          >
            {text}
          </SpanTag>
        </div>
        {withAction && (
          <Button onClick={handleClick} size="s" typeBtn="primary" type="button">
            {actionButtonText}
          </Button>
        )}
      </div>
    </Snackbar>
  );
};
