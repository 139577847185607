import React from 'react'
import styles from './zoom-scale.module.scss'
import { SlideProps } from './zoom-scale.types'

export const ZoomScale = ({
  onSlideZoom,
  defaultZoom,
  max,
  min,
  reverse = false,
  multiplicity = 1,
}: SlideProps) => {
  const onChange = (value: React.InvalidEvent<any>) => {
    const a = reverse
      ? value?.target?.value / -multiplicity
      : value?.target?.value / multiplicity
    onSlideZoom(a)
  }

  const maximum = reverse ? -min : max
  const minimum = reverse ? -max : min

  return (
    <>
      <div className={styles['slide-container']}>
        <input
          type={'range'}
          min={Math.floor(minimum)}
          max={Math.ceil(maximum)}
          className={styles.slider}
          value={reverse ? -defaultZoom : defaultZoom}
          id="myRange"
          onInput={(e) => onChange(e)}
        />
      </div>
    </>
  )
}
