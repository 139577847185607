import type { SchemeLayerProps, ContentWithDots } from './scheme-layer.types';
import type { Stage as StageType } from 'konva/lib/Stage';
import { Content } from '@/types/content.types';

import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Group, Image as KImage, Layer, Stage } from 'react-konva';

import { MiniViewToCenterButton } from '../mini-view-buttons';
import {
  boundFunc,
  getPoint,
  getViewParams,
  onWheel,
  reducer,
  initialState,
  loadDots,
  loadImage,
  setLoading,
  setStageView,
  resizeMarkers,
} from '../utils';
import { DotsGroup } from './dots-group';

import '../mini-view.scss';
import { Vector2d } from 'konva/lib/types';
import { useDeviceSelector } from '@/state';

export const SchemeLayer = memo(function SchemeLayerMemo({
  markers,
  contentID,
  switchPan,
  dateFrom,
  onLoadDots,
  schemeImage,
  schemePoints,
  yaw,
  isMapFullScreen,
  pointID,
  stageContainer,
  isMobile,
  onSetFullScreen,
  onSetSchemeCenter,
}: SchemeLayerProps) {
  const { isDesktop } = useDeviceSelector();
  const [state, dispatch] = useReducer(reducer, initialState(), initialState);
  const stageRef = useRef<StageType | null>(null);
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const dynamicScaleLimit = useMemo(() => {
    if (schemeImage && stageRef.current) {
      const res = Math.max(
        schemeImage.width / stageRef?.current.width(),
        schemeImage.height / stageRef?.current.width(),
      );

      return Math.trunc(res);
    }
    return 0;
  }, [schemeImage]);

  const setMarkers = useCallback(() => {
    if (
      stageRef.current &&
      schemePoints &&
      markers &&
      imageSize.width > 0 &&
      !state.load
    ) {
      const offset = {
        x: isMapFullScreen
          ? (stageRef.current.width() - imageSize.width * 0.8) / 2
          : (stageRef.current.width() - imageSize.width) / 2,
        y: isMapFullScreen
          ? (stageRef.current.height() - imageSize.height * 0.8) / 2
          : (stageRef.current.height() - imageSize.height) / 2,
      };
      const dots = markers.reduce(
        (acc: ContentWithDots[], marker: Content, i: number) => {
          if (Array.isArray(schemePoints) && schemePoints[i]) {
            const { x, y } = getPoint(
              schemePoints[i].schemePoint,
              schemeImage,
              imageSize,
              offset,
              isMapFullScreen,
            );
            acc.push({
              ...marker,
              x,
              y,
            });
          } else if (
            schemePoints.points &&
            schemePoints.points[dateFrom] &&
            schemePoints.points[dateFrom][i]
          ) {
            const { x, y } = getPoint(
              schemePoints.points[dateFrom][i].schemePoint,
              schemePoints.schemeFrameSize,
              imageSize,
              offset,
            );
            acc.push({
              ...marker,
              x,
              y,
            });
          } else if (
            schemePoints &&
            schemePoints.defaultPoints &&
            schemePoints.defaultPoints[i]
          ) {
            const { x, y } = getPoint(
              schemePoints.defaultPoints[i].schemePoint,
              schemePoints.schemeFrameSize,
              imageSize,
              offset,
            );
            acc.push({
              ...marker,
              x,
              y,
            });
          }
          return acc;
        },
        [],
      );
      !!onLoadDots && onLoadDots(dots);
      dispatch(loadDots(dots));
    }
  }, [dateFrom, markers, onLoadDots, imageSize, schemePoints, state.load, schemeImage]);

  const setViewParams = useCallback(() => {
    if (stageRef.current && stageContainer && stageContainer.current) {
      const stage = stageRef.current;
      const view = getViewParams(schemeImage, stageContainer.current);
      stage.setAttrs({ width: view.width, height: view.height });
      if (imageSize.width !== view.imageWidth && imageSize.height !== view.imageHeight) {
        setImageSize({
          width: view.imageWidth,
          height: view.imageHeight,
        });
      }
      dispatch(setStageView({ ...view }));
      setMarkers();
    }
  }, [imageSize, schemeImage, setMarkers]);

  const onResize = useCallback(() => {
    setViewParams();
    const newScale = 1;
    if (stageRef.current) {
      const layer = stageRef.current.getLayers()[0];
      if (layer && layer.getRelativePointerPosition()) {
        const x = -(0 - layer.getRelativePointerPosition().x / newScale) * newScale;
        const y = -(0 - layer.getRelativePointerPosition().y / newScale) * newScale;
        const pos =
          layer &&
          boundFunc({ x, y }, newScale, {
            width: layer.width(),
            height: layer.height(),
          });

        layer.position({ x: pos.x, y: pos.y });

        layer.scale({ x: newScale, y: newScale });
      }
      resizeMarkers(stageRef.current);
    }
  }, [setViewParams]);

  const onMarkerClick = useCallback(
    (e: number | string) => {
      !!switchPan && switchPan(e);
    },
    [switchPan],
  );

  const dragBoundFunc = useCallback((pos: { x: number; y: number }) => {
    const layer = stageRef.current?.getLayers()[0];

    const layerScaleX = layer ? layer.scale()?.x : null;

    if (layer && layerScaleX) {
      return boundFunc(pos, layerScaleX, {
        width: layer.width(),
        height: layer.height(),
      });
    }
  }, []);

  const loadScheme = useCallback(() => {
    if (!state.img) {
      if (schemeImage && stageContainer && stageContainer.current) {
        const image = new Image();
        image.onload = () => {
          setViewParams();
          dispatch(loadImage(image));
          dispatch(setLoading(false));
        };
        image.src = schemeImage.src;
      }
    } else {
      setViewParams();
    }
  }, [schemeImage, setViewParams, state.img]);

  useEffect(loadScheme, [loadScheme, isMapFullScreen, isMobile]);

  useEffect(() => {
    if (stageContainer.current && stageContainer.current) {
      const observer = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;
        stageRef.current?.width(width);
        stageRef.current?.height(height);
        onResize();
      });

      observer.observe(stageContainer.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [stageContainer.current]);

  useEffect(() => {
    onSetSchemeCenter.current = onResize;
  }, []);

  return (
    <>
      {isMapFullScreen
        ? isDesktop && (
            <div className="left-panel_scheme">
              <MiniViewToCenterButton
                onClick={onResize}
                isMapFullScreen={isMapFullScreen}
              />
            </div>
          )
        : null}
      <Stage ref={stageRef} onTap={onSetFullScreen}>
        <Layer
          id="baseLayer"
          name={'baseLayer'}
          draggable
          onWheel={onWheel(dynamicScaleLimit)}
          onScroll={onWheel(dynamicScaleLimit)}
          dragBoundFunc={dragBoundFunc as () => Vector2d}
        >
          {stageRef.current && (
            <Group id="schemeGroup">
              <KImage
                x={
                  isMapFullScreen
                    ? (stageRef.current.width() - imageSize.width * 0.8) / 2
                    : (stageRef.current.width() - imageSize.width) / 2
                }
                y={
                  isMapFullScreen
                    ? (stageRef.current.height() - imageSize.height * 0.8) / 2
                    : (stageRef.current.height() - imageSize.height) / 2
                }
                width={isMapFullScreen ? imageSize.width * 0.8 : imageSize.width}
                height={isMapFullScreen ? imageSize.height * 0.8 : imageSize.height}
                image={state.img}
              />
            </Group>
          )}
          <DotsGroup
            openFull={isMapFullScreen}
            yaw={yaw}
            pointID={pointID}
            dots={state.dots}
            switchPan={onMarkerClick}
            stage={stageRef.current!}
            contentID={contentID}
            isMobile={isMobile}
          />
        </Layer>
      </Stage>
    </>
  );
});
