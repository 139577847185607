import type {
  IAddDefaultSnackbarOptions,
  IAddErrorSnackbarOptions,
  IAddLoadingSnackbarOptions,
  IUpdateSnackbarOptions,
} from './snackbar.types'

import { eventbus } from '@/eventbus'

export const snackbarEventChannel = eventbus<{
  addSnack: (
    options:
      | IAddDefaultSnackbarOptions
      | IAddErrorSnackbarOptions
      | IAddLoadingSnackbarOptions,
  ) => void
  updateLoadingSnack: (options: IUpdateSnackbarOptions) => void
}>()
