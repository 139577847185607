import styles from '../profile-menu.module.scss';
import { H4Tag, SubTag } from '../../common';
import React from 'react';
import { ProfileMenuHeaderProps } from '../profile-menu.types';
import { Profile } from '../../common/profile';


export const ProfileMenuHeader = ({ isMenuOpened, src,  name = 'name', email = 'johndoe@example.com'  } : ProfileMenuHeaderProps) => {
  return <div className={styles['profile-menu-header']}>
    <div className={styles['profile-menu-header-avatar']}>
      <Profile isProfileMenuOpened={isMenuOpened} src={src}/>
    </div>
    <div className={styles['profile-menu-header-content']}>
      <H4Tag className={styles.name}>{name}</H4Tag>
      <SubTag className={styles.email}>{email}</SubTag>
    </div>
  </div>;
};