import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../profile-menu.module.scss';
import { Button } from '../../common';
import { ProfileMenuContentProps } from '../profile-menu.types';
import { logout } from '../../../state';
import { useAppDispatch } from '../../../state';
import { Paths } from '../../../constants';

export const ProfileMenuContent = ({ children }: ProfileMenuContentProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={styles['profile-menu-content']}>
      {/* <Button
      elementType={'button'}
      text={'Перейти в профиль'}
      justifyContent={'start'}
      typeBtn={'uncolored'}
      showLeftIcon={true}
      leftIcon={<i className="icon icon-user icon-middle"></i>}
      onClick={() => navigate({ pathname: `${Paths.MAIN_PAGE}${Paths.PROFILE}`, search: searchParams.toString() })}
    /> */}
      <Button
        text={'Выйти'}
        elementType={'button'}
        typeBtn={'danger'}
        showLeftIcon={true}
        justifyContent={'start'}
        onClick={() => dispatch(logout())}
        leftIcon={<i className="icon icon-log-out icon-middle"></i>}
      />
    </div>
  );
};
