import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './search.module.scss'
import { SearchProps } from './search.types'
import { SearchDropdown, SearchInput } from '../common'
import { useComponentVisible } from '@/hooks'
import { buildSearchResults } from './utils'
import { useSelectObject } from '@hooks/depended/useSelectObject'
import { centeringToMarker } from "@eventbus/events/map/project-layer";


export const Search = ({
  className,
  children,
  items = [],
  ...props
}: SearchProps) => {
  const [searchRef, showSuggestions, setShowSuggestions] = useComponentVisible(
    () => false,
  )
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputQueryValue, setInputValue] = useState<string>('')
  const { firstSuggestionId, suggestions } =
    buildSearchResults(items, inputQueryValue.trim())

  const [, setSelectedObjectId] = useSelectObject()
  const [activeSuggestion, setSuggestion] = useState(0) // активный индекс в массиве
  const [, setActiveSuggestionId] = useState<number | null>( // id объекта
    firstSuggestionId,
  )
  const [isFocused, setFocused] = useState<boolean>(false)

  const preparePlaceholder = ( value: string, placeholder: string ) => {

    const preparedValue = value?.toLowerCase() || ''
    const preparedPlaceholder = placeholder?.toLowerCase()
    const realIndex = preparedPlaceholder?.search(preparedValue)

    if ( realIndex >= 0 ) {
      return value + placeholder?.slice(realIndex + preparedValue?.length, preparedPlaceholder?.length)
    }
    return ''
  }

  const suggestedName = inputQueryValue === ''
    ? inputRef.current?.placeholder
    : preparePlaceholder( inputQueryValue, suggestions[activeSuggestion]?.name)

  const onInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setInputValue(val)
  }

  const resetInputValue = () => {
    setInputValue('')
  }

  const showDropdownOnInputFocus = () => {
    setFocused(true)
    setShowSuggestions(true)
  }

  const hideDropdownOnLooseInputFocus = () => {
    setFocused(false)
    setShowSuggestions(false)
  }

  const setPlaceholder = (
    arrow?: 'up' | 'down' | 'initial' | '',
    value?: string,
  ) => {
    if (arrow === 'up') {
      return inputRef?.current?.setAttribute(
        'placeholder',
        suggestions[activeSuggestion - 1]?.name || '',
      )
    }
    if (arrow === 'down') {
      return inputRef?.current?.setAttribute(
        'placeholder',
        suggestions[activeSuggestion + 1]?.name || '',
      )
    }
    if (arrow === 'initial') {
      return inputRef?.current?.setAttribute('placeholder', 'Проект, адрес')
    }
    if (value) {
      return inputRef?.current?.setAttribute('placeholder', value)
    }
  }

  const handleMouseEnterOnSearchSuggestion = (idx: number) => {
    setSuggestion(idx)
    setPlaceholder('', suggestions[idx]?.name)
  }

  const resetOnBlur = () => {
    setPlaceholder('initial')
    //setSuggestion(0);
  }

  useEffect(() => {
    inputQueryValue && setShowSuggestions(true)
    setSuggestion(0)
    inputQueryValue && setPlaceholder('', suggestions[activeSuggestion]?.name)
    inputQueryValue === '' && setPlaceholder('initial')
  }, [inputQueryValue])

  useEffect(() => {
    isFocused || setPlaceholder('initial')
    isFocused || setInputValue('')
  }, [isFocused])

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    const { code } = e
    if (code === 'Enter') {
      centeringToMarker({ id: suggestions[activeSuggestion].id })
      setSelectedObjectId(suggestions[activeSuggestion].id)
      hideDropdownOnLooseInputFocus()
      resetInputValue()
      setFocused(false)
      inputRef?.current?.blur()
    }

    if (code === 'Escape') {
      resetInputValue()
      setPlaceholder('initial')
    }

    if (code === 'Tab') {
      setInputValue(suggestions[activeSuggestion]?.name)
      e.preventDefault()
    }

    if (code === 'ArrowUp') {
      isFocused && activeSuggestion > 0 && setSuggestion(activeSuggestion - 1)
      activeSuggestion > 0 &&
        setActiveSuggestionId(suggestions[activeSuggestion].id)
      setPlaceholder('up')
    }

    if (code === 'ArrowDown') {
      isFocused &&
      activeSuggestion < suggestions.length - 1 &&
      activeSuggestion < 5
        ? setSuggestion(activeSuggestion + 1)
        : setSuggestion(0)
      activeSuggestion < suggestions.length &&
        setActiveSuggestionId(suggestions[activeSuggestion].id)
      setShowSuggestions(true)
      setPlaceholder('down')
    }
  }

  return (
    <div className={styles.container} ref={searchRef}>
      <SearchInput
        onBlur={resetOnBlur}
        inputRef={inputRef}
        onKeyDown={handleKeyboardEvent}
        onFocus={showDropdownOnInputFocus}
        resetInputValue={resetInputValue}
        placeholder={'Объект, инициатор , адрес'} //'Объект, иницатор, адрес'
        onChange={onInputChangeHandler}
        value={inputQueryValue}
        suggestedName={suggestedName}
      />

      {showSuggestions && (
        <div className={styles['dropdown-container']}>
          {Array.isArray(items) && (
            <SearchDropdown
              inputQueryValue={inputQueryValue}
              handleMouseEnterOnSearchSuggestion={
                handleMouseEnterOnSearchSuggestion
              }
              activeSuggestion={activeSuggestion}
              hideDropdown={hideDropdownOnLooseInputFocus}
              items={suggestions}
            />
          )}
        </div>
      )}
    </div>
  )
}
