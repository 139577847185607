import { uniqBy } from 'lodash';
import { ObjectInterface } from '@/types';

export const buildSearchResults = (
  items: [] | ObjectInterface[],
  inputQueryValue: string,
): {
  firstSuggestionId: number | null
  suggestions: [] | ObjectInterface[]
  filteredSuggestions: [] | ObjectInterface[]
} => {
  const filterObjectsByQuery = (item: ObjectInterface, query = '') => {

    const string = `${item.name ? item.name.toLowerCase() + ' ' : ''}${item.place ? item.place.toLowerCase() + ' ' : ''}${item.owner ? item.owner.toLowerCase() + ' ' : ''}`;

    return string.includes(query.toLowerCase());
  };
  
  
  const filteredResults = items.filter((item) => {
    
    return filterObjectsByQuery(item, inputQueryValue.toLowerCase());
  });

  const uniqueResult = uniqBy(filteredResults,'id')

  return {
    firstSuggestionId: (filteredResults && filteredResults[0]?.id) || null,
    filteredSuggestions: filteredResults,
    suggestions: uniqueResult,
  };
};

