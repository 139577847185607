import React from 'react';
import { ViewSwitcherProps } from './view-switcher.types';
import { Button } from '../common';
import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';


export const ViewSwitcher = ({
  role = 'redactor',
  view = 'map',
  onSelectViewMode,
}: ViewSwitcherProps) => {
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  const viewModes = {
    0: 'map',
    1: 'list',
    2: 'table',
  };

  const setButtonValue = () => {
    if (isTabletLandscape) return '';
    if (view === 'list') return 'Объекты на карте';
    return 'Объекты списком';
  };


  return (
    <Button
      size={isTabletLandscape ? 'm' : 'l'}
      onClick={
        view === 'map'
          ? () => onSelectViewMode(viewModes[1])
          : () => onSelectViewMode(viewModes[0])
      }
      elementType={'button'}
      onlyIcon={isTabletLandscape}
      typeBtn={'elevation'}
      showRightIcon={true}
      rightIcon={
        view === 'list' ? (
          <i className={`icon icon-map icon-${isTabletLandscape ? 'tiny' : 'middle'}`}></i>
        ) : (
          <i className={`icon icon-list icon-${isTabletLandscape ? 'tiny' : 'middle'}`}></i>
        )
      }
      text={setButtonValue()}
    />
  );
};
