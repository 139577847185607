import { useMemo } from 'react';

export const useDomElement = (selector: string) => {
  const targetParentElement: HTMLDivElement | null = useMemo(
    () => document.querySelector(selector),
    [selector],
  );

  return targetParentElement;
};
