import type { SidebarPointOfInterestProps } from './sidebar-point-of-interest.types';

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnyObject } from 'yup/es/types';

import { pointsOfInterestImages } from '@/___prototype';
import { MediaTypes } from '@/constants';
import { Button, CommonImage, SidebarContainer, SpanTag } from '../common';

import styles from './sidebar-point-of-interest.module.scss';
import classnames from 'classnames';

export const SidebarPointOfInterest: FC<SidebarPointOfInterestProps> = ({
  handleCloseSidebar,
  onImageBtnClick,
  onVideoBtnClick,
  showSidebar,
  pointsOfInterestID,
}) => {
  const { objectID, contentType } = useParams();

  const [selectedItem, setSelectedItem] = useState<AnyObject>();

  const sidebarAvailable = !!objectID && !!pointsOfInterestID;
  const imageAvailable = sidebarAvailable && contentType === MediaTypes.PHOTO;
  const videoAvailable = sidebarAvailable && contentType === MediaTypes.VIDEO;

  useEffect(() => {
    const qwe = contentType as MediaTypes;
    let item;

    switch (qwe) {
      case MediaTypes.PANORAMA:
        item =
          pointsOfInterestImages?.[
            pointsOfInterestID as keyof typeof pointsOfInterestImages
          ]?.[0] || null;
        setSelectedItem(item as any);
        break;
      case MediaTypes.VIDEO:
        break;
      case MediaTypes.IMAGES:
        item =
          pointsOfInterestImages?.[
            pointsOfInterestID as keyof typeof pointsOfInterestImages
          ]?.[0] || null;
        setSelectedItem(item as any);
        break;
      default:
        break;
    }
  }, [pointsOfInterestID, contentType]);

  if (!showSidebar) {
    return null;
  }

  return (
    <SidebarContainer className={styles.sidebar}>
      <div className={styles['content-wrapper']}>
        <div className={styles['sidebar-header']}>
          <h4 className={styles['sidebar-header__title']}>
            {selectedItem?.name || 'Название точки интереса'}
          </h4>
          <Button
            showLeftIcon
            onlyIcon
            typeBtn={'uncolored'}
            onClick={handleCloseSidebar}
            size={'s'}
            leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
          />
        </div>
        <div className={styles['levels-cards']}>
          <CommonImage
            className={styles.image}
            image={selectedItem?.original ?? selectedItem?.thumbnail}
            alt={selectedItem?.originalAlt}
            height={172}
          />
          <div className={styles['button-container']}>
            <Button
              size={'m'}
              elementType={'button'}
              typeBtn={'secondary'}
              onClick={imageAvailable ? handleCloseSidebar : onImageBtnClick}
              isActive={imageAvailable}
              showLeftIcon={true}
              leftIcon={
                <i
                  className={classnames(
                    'icon',
                    imageAvailable ? 'icon-close-3' : 'icon-camera',
                    'icon-middle',
                  )}
                />
              }
              className={styles.button}
            >
              Фото
            </Button>
            <Button
              size={'m'}
              elementType={'button'}
              typeBtn={'secondary'}
              onClick={videoAvailable ? handleCloseSidebar : onVideoBtnClick}
              isActive={videoAvailable}
              showLeftIcon={true}
              leftIcon={
                <i
                  className={classnames(
                    'icon',
                    videoAvailable ? 'icon-close-3' : 'icon-video',
                    'icon-middle',
                  )}
                />
              }
              className={styles.button}
              disabled
            >
              Видео
            </Button>
          </div>
          <div className={styles['card-wrapper']}>
            {selectedItem?.groupDescription && (
              <div className={styles.card}>
                <SpanTag className={styles['card-title']}>
                  Общее описание группы объектов:
                </SpanTag>
                <SpanTag className={styles['card-value']}>
                  {selectedItem?.groupDescription || 'Описание не найдено'}
                </SpanTag>
              </div>
            )}
            <div className={styles.card}>
              <SpanTag className={styles['card-title']}>
                {selectedItem?.groupDescription ? 'Описание элемента: ' : 'Описание: '}
              </SpanTag>
              <SpanTag className={styles['card-value']}>
                {selectedItem?.description || 'Описание не найдено'}
              </SpanTag>
            </div>
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};
