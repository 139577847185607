import styles from './search-input.module.scss';
import type { SearchInputProps } from './search-input.types';
import { Button } from '../button';
import React from 'react';
import { useViewModeSelector } from '../../../history-state/selectors';

export const SearchInput = ({
                              inputRef,
                              resetInputValue,
                              resetFilter,
                              searchingFilter = '',
                              error = '',
                              helperText = '',
                              value = '',
                              label = '',
                              onChange,
                              suggestedName = '',
                              ...props
                            }: SearchInputProps): JSX.Element => {
  const [viewMode] = useViewModeSelector();

  return (
    <label
      className={`${styles['input-field-label']} ${
        viewMode === 'list' ? styles['label-list-view-bs'] : ''
      }`}
    >
      {label && (
        <span className={`${styles['input-field-label-text']} ${!!error ? 'error' : ''}`}>
        {label}
      </span>
      )}
      <span className={styles['input-suggestion']}>
        {value ? suggestedName : ''}
      </span>
      <span className={styles['input-field-input-wrapper']}>
        <input
          ref={inputRef}
          autoComplete="off"
          name="text"
          type="text"
          className={`${styles['input-field-input']} ${!!error ? 'error' : ''}`}
          onChange={(e) => onChange && onChange(e)}
          value={value}
          onFocus={() => console.log('focused')}
          style={{ paddingLeft: searchingFilter === '' ? 55 : 200 }}
          {...props}
        />
        <i
          className={`${styles['input-field-input-icon-search']} icon icon-middle icon-search`}
        />
        {/*{searchingFilter === '' || (*/}
        {/*  <Button*/}
        {/*    className={styles['input-field-input-filter-tag']}*/}
        {/*    elementType={'button'}*/}
        {/*    typeBtn={'primary'}*/}
        {/*    showRightIcon={true}*/}
        {/*    size={'s'}*/}
        {/*    rightIcon={<i className="icon icon-close-3 icon-low"></i>}*/}
        {/*    text={*/}
        {/*      searchingFilter === 'object'*/}
        {/*        ? 'Объекты'*/}
        {/*        : searchingFilter === 'address'*/}
        {/*          ? 'Адрес'*/}
        {/*          : 'Инициатор'*/}
        {/*    }*/}
        {/*    onClick={resetFilter}*/}
        {/*  />*/}
        {/*)}*/}
        {value === '' || (
          <Button
            onlyIcon={true}
            className={styles['input-field-input-close-btn']}
            elementType={'button'}
            typeBtn={'uncolored'}
            showRightIcon={true}
            size={'s'}
            rightIcon={<i className="icon icon-close-3 icon-middle"></i>}
            onClick={resetInputValue}
          />
        )}
      </span>
      {!!error && <span className={styles['input-field-invalid-field']}>{error}</span>}
    </label>
  );
};
