/* eslint-disable */
import { nanoid } from 'nanoid';
import { ObjectWorkDirections } from './program';
import { formatDate } from '@/utils';
import { lineString } from '@turf/helpers';
import lineLength from '@turf/length';

import poi20 from './points-f.json';
import poi1 from './streets-pois/obj-id-1.json';
import poi2 from './streets-pois/obj-id-2.json';
import poi3 from './streets-pois/obj-id-3.json';
import poi4 from './streets-pois/obj-id-4.json';
import poi5 from './streets-pois/obj-id-5.json';
import poi7 from './streets-pois/obj-id-7.json';
import poi10 from './streets-pois/obj-id-10.json';
import poi11 from './streets-pois/obj-id-11.json';
import poi12 from './streets-pois/obj-id-12.json';
import poi13 from './streets-pois/obj-id-13.json';

import streetsGeoJSON from './result-f.json';
import { Content } from '@/types';
import { AnyObject } from 'yup/es/types';

const streetsPOIs = {
  1: poi1,
  2: poi2,
  3: poi3,
  4: poi4,
  5: poi5,
  7: poi7,
  10: poi10,
  11: poi11,
  12: poi12,
  13: poi13,
} as const;

const getThumbnailUrl = (path: string) => {
  try {
    return require(`./images/thumbnails${path}`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const streets = (() => {
  const thumbs: { [key: string]: string | undefined } = {};

  return streetsGeoJSON.features.map((street) => {
    if (!thumbs[street.properties.object_id] && street.properties.thumbnail) {
      thumbs[street.properties.object_id] = getThumbnailUrl(street.properties.thumbnail);
    }

    const distance = parseFloat(
      lineLength(lineString(street.geometry.coordinates), {
        units: 'kilometers',
      }).toFixed(2),
    );

    return {
      coords: {
        coordinates:
          street.geometry.coordinates[Math.trunc(street.geometry.coordinates.length / 2)],
      },
      id: street.properties.object_id,
      type_id: 4,
      work_type: street.properties.work_type,
      object_type: street.properties.object_type,
      lineId: 4,
      name: street.properties.name,
      place: '',
      poi: streetsPOIs[street.properties.object_id as keyof typeof streetsPOIs] ?? [],
      media: [],
      thumbnail: thumbs[street.properties.object_id],
      is_line: true,
      distance,
      square: distance * 1000 * 9,
    };
  });
})();

const realizationStatuses = {
  1: 'Договор заключен/Деньги выделены',
  2: 'В процессе реализации',
  3: 'Проект реализован',
} as const;

const works = [
  {
    label:
      'Мощение тротуаров новыми гранитными плитами и мелкоразмерным гранитным камнем',
    value: `17 818 M²`,
    id: nanoid(),
  },
  {
    label: 'Установка новых гранитных пандусов для ММГН',
    value: `132 M²`,
    id: nanoid(),
  },
  {
    label: 'Установка нового гранитного бордюра',
    value: `5 645 пог. м`,
    id: nanoid(),
  },
  {
    label: 'Установка гранитных водоприемных чаш',
    value: `226 шт.`,
    id: nanoid(),
  },
  {
    label: 'Установка гранитных скамеек',
    value: `14 шт.`,
    id: nanoid(),
  },
  {
    label: 'Посадка деревьев',
    value: `26 шт.`,
    id: nanoid(),
  },
  {
    label: 'Посадка кустарников',
    value: `300 шт.`,
    id: nanoid(),
  },
  {
    label: 'Установка газонных ограждений',
    value: `3 338 пог. м`,
    id: nanoid(),
  },
  {
    label: 'Восстановление газонов',
    value: `1 842,6 M²`,
    id: nanoid(),
  },
  {
    label: 'Установка урн',
    value: `57 шт.`,
    id: nanoid(),
  },
  {
    label: 'Замена дверей',
    value: `32 шт.`,
    id: nanoid(),
  },
];

const poisInfo: Record<
  string,
  { name: string; description: string; groupDescription: string }
> = {
  'poi-1': {
    name: 'Фонарный столб',
    description: `Фонарный столб имеет ствол в виде единой конструкции – стойки цилиндрической формы в соответствии с требованиями ГОСТ 32947-2014. Изготовлен из металла. 
Высота столба 6 метров. 
Имеет декоративные сферические светильники.`,
    groupDescription: `На протяженности всей ул. Чайковского установлены фонарные столбы в количестве _ штук, с шагом установки 4 метра. 
При определении расстрояния установки придерживался прнцип соотношения высоты опоры к шагу расположения 1:7 при классической расстановке столбов напротив друг друга`,
  },
  'poi-2': {
    name: 'Скамейка с клумбой',
    description: `Скамейка радиусная без подлокотников с центральной клумбой для высадки декоративных кустарников. Каркас скамейки выполнен из гранита, что повышает прочность и долговечность всей конструкции. Материал сиденья - брус хвойных пород. 
Глубина зоны посадки 60 см, диаметр - 150 см.`,
    groupDescription: `На протяженности ул. Чайковского установлено 14 радиусных скамеек с клумбами для декоративных кустарников. Конструкции являются антивандальными, в клумбы высажены лиственные многолетние полукустарники.`,
  },
  'poi-3': {
    name: 'Парковочный столб',
    description: '',
    groupDescription: '',
  },
  'poi-4': {
    name: 'Бордюр пешеходного перехода',
    description: `На данном участке тротуара выполнен бордюр пешеходного перехода с соблюдением требований к понижению бордюров пешеходных переходов. Высота бордюра на нижней грани съезда у перехода составляет 0 мм. Ширина съезда - 2 метра. `,
    groupDescription: `На всей протяженности ул. Чайковского установлено 5 645 погонных метров тротуарного гранитного бордюра. 
Цвет: Серый
Вес: 49 кг/м2`,
  },
  'poi-5': {
    name: 'Светофор',
    description: `На данном участке тротуара выполнен бордюр пешеходного перехода с соблюдением требований к понижению бордюров пешеходных переходов. Высота бордюра на нижней грани съезда у перехода составляет 0 мм. Ширина съезда - 2 метра. `,
    groupDescription: '',
  },
  'poi-6': {
    name: 'Фонарный столб',
    description: `Фонарный столб имеет ствол в виде единой конструкции – стойки цилиндрической формы в соответствии с требованиями ГОСТ 32947-2014. Изготовлен из металла. 
Высота столба 6 метров. 
Имеет декоративные сферические светильники. `,
    groupDescription: `На протяженности всей ул. Чайковского установлены фонарные столбы в количестве _ штук, с шагом установки 4 метра. 
При определении расстрояния установки придерживался прнцип соотношения высоты опоры к шагу расположения 1:7 при классической расстановке столбов напротив друг друга`,
  },
  'poi-7': {
    name: 'Скамейка с клумбой',
    description: `Скамейка радиусная без подлокотников с центральной клумбой для высадки декоративных кустарников. Каркас скамейки выполнен из гранита, что повышает прочность и долговечность всей конструкции. Материал сиденья - брус хвойных пород. 
    Глубина зоны посадки 60 см, диаметр - 150 см.`,
    groupDescription: `На протяженности ул. Чайковского установлено 14 радиусных скамеек с клумбами для декоративных кустарников. Конструкции являются антивандальными, в клумбы высажены лиственные многолетние полукустарники. `,
  },
  'poi-8': {
    name: 'Скамейка с клумбой',
    description: `Скамейка радиусная без подлокотников с центральной клумбой для высадки декоративных кустарников. Каркас скамейки выполнен из гранита, что повышает прочность и долговечность всей конструкции. Материал сиденья - брус хвойных пород. 
Глубина зоны посадки 60 см, диаметр - 150 см.`,
    groupDescription: `На протяженности ул. Чайковского установлено 14 радиусных скамеек с клумбами для декоративных кустарников. Конструкции являются антивандальными, в клумбы высажены лиственные многолетние полукустарники. `,
  },
  'poi-16': {
    name: 'Забор',
    description: `Металлическое газонное ограждение. Сварные секции изготовлены с ровным верхом. Для защиты от коррозии сварные секции и опорные столбы покрыты грунт-эмалью, сверху нанесен состав на основе эпоксидной смолы и полиуретана, произведено напыление порошковой краски черного цвета.`,
    groupDescription: `Газонные ограждения на ул. Чайковского выполняют одновременно несколько функций — защищают газоны и клумбы от машин и пешеходов, зонируют территорию, выполняют роль декоративного элемента в ландшафтном дизайне. Всего на протяжении улицы установлено 3 338 погонных метров газонных ограждений,  выполнено восстановление газонов 1 842,6 метров квадратных. `,
  },
  'poi-22': {
    name: 'Фонарный столб',
    description: `Фонарный столб имеет ствол в виде единой конструкции – стойки цилиндрической формы в соответствии с требованиями ГОСТ 32947-2014. Изготовлен из металла. 
Высота столба 6 метров. 
Имеет декоративные сферические светильники. `,
    groupDescription: `На протяженности всей ул. Чайковского установлены фонарные столбы в количестве _ штук, с шагом установки 4 метра. 
При определении расстрояния установки придерживался прнцип соотношения высоты опоры к шагу расположения 1:7 при классической расстановке столбов напротив друг друга`,
  },
  'poi-23': {
    name: 'Дверь',
    description: `Дверь шумоизоляционная парадная двустворчатая, филенчатая, с резным декором и антивандальными стеклянными вставками. 
Профиль дверной коробки 50х25 мм. `,
    groupDescription: `В домах, расположенных на ул. Чайковского заменено, в общей сложности, 32 входные двери. Установка дверей в парадных проведена в соответствии с требованиями по работе в объектами культурного наследия. `,
  },
  'poi-25': {
    name: 'Зеленые насаждения',
    description: '',
    groupDescription: `Выполнена высадка 26 мелколистных лип и 300 кустарников `,
  },
  'poi-26': {
    name: 'Зеленые насаждения',
    description: '',
    groupDescription: `Выполнена высадка 26 мелколистных лип и 300 кустарников `,
  },
};
poisInfo['poi-9'] = poisInfo['poi-2'];
poisInfo['poi-10'] = poisInfo['poi-4'];
poisInfo['poi-11'] = poisInfo['poi-5'];
poisInfo['poi-12'] = poisInfo['poi-6'];
poisInfo['poi-13'] = poisInfo['poi-8'];
poisInfo['poi-14'] = poisInfo['poi-7'];
poisInfo['poi-15'] = poisInfo['poi-2'];
poisInfo['poi-17'] = poisInfo['poi-6'];
poisInfo['poi-18'] = poisInfo['poi-8'];
poisInfo['poi-19'] = poisInfo['poi-7'];
poisInfo['poi-20'] = poisInfo['poi-3'];
poisInfo['poi-21'] = poisInfo['poi-22'];
poisInfo['poi-24'] = poisInfo['poi-8'];

export const pointOfInterest = [
  {
    id: 0,
    panoramaStepId: 1, // номер шага в панорамах
    name: poisInfo['poi-1'].name,
    contentId: 'poi-1', // id точки интереса в этом шаге панорамы
    contentType: 'PANORAMA',
    yaw: -0.8078,
    contentDate: '2023-04-06',
    work_type: 3,
    object_type: 2,
    coords: {
      lat: 59.94664,
      long: 30.34291,
    },
  },
  {
    id: 1,
    panoramaStepId: 1,
    name: poisInfo['poi-2'].name,
    contentId: 'poi-2',
    yaw: -0.8078,
    contentType: 'PANORAMA',
    work_type: 3,
    object_type: 1,
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94663,
      long: 30.34309,
    },
  },
  {
    id: 2,
    panoramaStepId: 1,
    name: poisInfo['poi-3'].name,
    work_type: 3,
    object_type: 1,
    contentId: 'poi-3',
    yaw: 0.9859,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.34318,
    },
  },
  {
    id: 3,
    panoramaStepId: 1,
    name: poisInfo['poi-4'].name,
    contentId: 'poi-4',
    work_type: 3,
    object_type: 1,
    yaw: 1.627,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.34304,
    },
  },
  {
    id: 4,
    panoramaStepId: 1,
    name: poisInfo['poi-5'].name,
    contentId: 'poi-5',
    work_type: 3,
    object_type: 1,
    yaw: 1.9937,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.3429,
    },
  },

  {
    id: 5,
    panoramaStepId: 2,
    name: poisInfo['poi-6'].name,
    contentId: 'poi-6',
    work_type: 3,
    object_type: 2,
    yaw: 0.6738,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.3433,
    },
  },
  {
    id: 6,
    panoramaStepId: 2,
    name: poisInfo['poi-7'].name,
    yaw: 0.6738,
    work_type: 3,
    object_type: 1,
    contentId: 'poi-7',
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.34345,
    },
  },
  {
    id: 7,
    panoramaStepId: 2,
    name: poisInfo['poi-8'].name,
    yaw: 0.3821,
    work_type: 3,
    object_type: 1,
    contentId: 'poi-8',
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.34357,
    },
  },

  {
    id: 8,
    panoramaStepId: 3,
    name: poisInfo['poi-16'].name,
    contentType: 'PANORAMA',
    yaw: -0.8019,
    work_type: 3,
    object_type: 1,
    contentId: 'poi-16',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94664,
      long: 30.34348,
    },
  },

  {
    id: 9,
    panoramaStepId: 4,
    contentId: 'poi-21',
    name: poisInfo['poi-21'].name,
    work_type: 3,
    object_type: 2,
    contentType: 'PANORAMA',
    yaw: -1.5317,
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94664,
      long: 30.34358,
    },
  },

  {
    id: 10,
    panoramaStepId: 5,
    contentId: 'poi-23',
    name: poisInfo['poi-23'].name,
    work_type: 3,
    object_type: 1,
    yaw: -1.1084,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94672,
      long: 30.34395,
    },
  },
  {
    id: 11,
    panoramaStepId: 5,
    name: poisInfo['poi-26'].name,
    contentId: 'poi-26',
    work_type: 3,
    object_type: 1,
    yaw: 0.4015,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.34395,
    },
  },
  {
    id: 12,
    panoramaStepId: 5,
    name: poisInfo['poi-25'].name,
    contentId: 'poi-25',
    work_type: 3,
    object_type: 1,
    yaw: 0.4015,
    contentType: 'PANORAMA',
    contentDate: '2023-04-06',
    coords: {
      lat: 59.94656,
      long: 30.3442,
    },
  },

  ...poi20,
];

export const defaultItems = [
  {
    coords: { coordinates: [30.35331, 59.94652] },
    id: 20,
    type_id: 4,
    work_type: 3,
    object_type: 1,
    lineId: 4,
    projectId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Улица Чайковского',
    place: '636611, Томская обл., Парабельский р-н, с. Нарым, ул. Школьная 16',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: pointOfInterest,
    media: [],
    works: works,
    thumbnail: getThumbnailUrl('/object_20.webp'),
    is_line: true,
    fixedCoords: { coordinates: [30.344565, 59.946822] },
  },

  ...streets,

  {
    coords: { coordinates: [30.35234, 59.943034] },
    id: 99,
    type_id: 4,
    work_type: 2,
    object_type: 3,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Спасо-Преображенский собор',
    place: 'Преображенская площадь, 1, Санкт-Петербург, 191028',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_99.webp'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.344565, 59.946822] },
    id: 98,
    type_id: 4,
    work_type: 2,
    object_type: 4,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Доходный дом Куракиной',
    place: 'ул. Чайковского, 8, Санкт-Петербург',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_98.jpeg'),
    is_line: false,
  },
  {
    coords: { coordinates: [30.305825, 59.916451] },
    id: 97,
    type_id: 4,
    work_type: 2,
    object_type: 2,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Троицкий собор',
    place: 'Измайловский просп., 7А',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_97.jpeg'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.309429, 59.908152] },
    id: 96,
    type_id: 4,
    work_type: 2,
    object_type: 3,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Церковь Воскресения Христова',
    place: 'набережная Обводного канала, 116',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_96.jpeg'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.33483, 59.919915] },
    id: 95,
    type_id: 4,
    work_type: 3,
    object_type: 2,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'ТЮЗ им. Брянцева',
    place: 'Пионерская площадь, 1',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_95.jpeg'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.353482, 59.91627] },
    id: 94,
    type_id: 4,
    work_type: 2,
    object_type: 3,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Крестовоздвиженский собор',
    place: 'Лиговский проспект, 128',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_94.jpeg'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.394809, 59.948861] },
    id: 93,
    type_id: 4,
    work_type: 2,
    object_type: 2,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Смольный собор',
    place: 'площадь Растрелли, 1',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_93.jpeg'),
    is_line: false,
  },

  {
    coords: { coordinates: [30.3376, 59.940735] },
    id: 92,
    type_id: 4,
    work_type: 2,
    object_type: 3,
    lineId: 4,
    macro_region: null,
    national_project: '',
    realization_status: realizationStatuses[3],
    direction: '',
    description_of_charitable_works: '',
    budget_allocation: '',
    scope_and_stages_of_work: '',
    gratitude: null,
    rating: 10,
    money_needs: '',
    name: 'Михайловский замок',
    place: 'Садовая улица, 2',
    program: 2,
    real_program: 1,
    real_status: 0,
    icon_select: '',
    reason:
      'Финансирование работ по ремонту крыши здания, крыльца и электропроводки в спортивном зале учреждения',
    region: 66,
    poi: [],
    media: [],
    thumbnail: getThumbnailUrl('/object_92.jpeg'),
    is_line: false,
  },
];

const getRandomTime = (end: Date): string => {
  const start = new Date(2012, 0, 1);
  const date = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime()),
  );
  return formatDate(date);
};

export const prepare = (array: AnyObject[]): AnyObject[] => {
  return array.map((item) =>
    item.id !== 20
      ? {
          ...item,
          direction: ObjectWorkDirections[Math.floor(Math.random() * 8)]?.id,
          // distance: item?.distance ? : Math.floor(Math.random() * 10_000),
          distance: item?.distance ? item?.distance : null,
          // square: Math.floor(Math.random() * 100_000),
          square: item?.square ? item?.square : 1000,
          start_date: getRandomTime(new Date(2020, 0, 2)),
          end_date: getRandomTime(new Date()),
        }
      : {
          ...item,
          direction: ObjectWorkDirections[Math.floor(Math.random() * 8)]?.id,
          distance: 3.36,
          square: '',
          start_date: '02.05.2020',
          end_date: '30.11.2020',
        },
  );
};

export const items = prepare(defaultItems);

export const mockPanoramas = [
  {
    id: 44,
    src: {
      raw: '/storage/4/2023/4/45dunslg4xve3m_raw.jpg?sk=1b787b86-7cd0-47ac-b6dd-90c48049dcbc',
      src: '/storage/4/2023/4/45dunslg4xve3m_src.jpg?sk=9d7a4c71-a32c-41ee-946b-d513d4ff49b6',
      tmb: '/storage/4/2023/4/45dunslg4xve3m_tmb.jpg?sk=21d39f40-deef-4033-be27-d779438b9f08',
      tiles: {
        baseDir: '/storage/4/2023/4/45dunslg4xve3m',
      },
    },
    type: 'PANORAMA',
    date: '2023-04-06',
    gps: {
      lat: 59.946602,
      long: 30.343005555555553,
    },
    description: '',
    magneticAngle: -92,
    gpsAccuracy: null,
    status: 'ACTIVE',
    contentCreateDate: '2023-04-05T14:59:53.000Z',
    pointId: 1,
    meta: null,
    sublineId: [],
    directions: [
      //точка перехода
      {
        contentId: 45,
        angle: 0,
      },
      //точка перехода

      /*ТОЧКИ ИНТЕРЕСА*/
      {
        contentId: 'poi-1', // фонарный столб
        angle: -49,
      },
      {
        contentId: 'poi-2', // лавочка-клумба рядом с фонарным столбом
        angle: -45,
        pitch: 0.14,
      },
      {
        contentId: 'poi-3', // знак "P" рядом с лексусом
        angle: 58,
        pitch: 0.16,
      },
      {
        contentId: 'poi-4', // бордюр дорожного перехода
        angle: 81,
        pitch: 0.38,
      },
      {
        contentId: 'poi-5', // светофор
        angle: 108,
        pitch: 0.15,
      },
      /*ТОЧКИ ИНТЕРЕСА*/
    ],
    defaultViewAngle: 0,
    viewAngle: 0,
  },
  {
    id: 45,
    src: {
      raw: '/storage/4/2023/4/45dunslg4xvlg7_raw.jpg?sk=79ea84d1-de1f-487b-ae59-c354868ad17b',
      src: '/storage/4/2023/4/45dunslg4xvlg7_src.jpg?sk=7aa77e76-5d20-4acd-9476-eecfd253f594',
      tmb: '/storage/4/2023/4/45dunslg4xvlg7_tmb.jpg?sk=14b96ab3-1c85-48d0-af7f-e9f663c500eb',
      tiles: {
        baseDir: '/storage/4/2023/4/45dunslg4xvlg7',
      },
    },
    type: 'PANORAMA',
    date: '2023-04-06',
    gps: {
      lat: 59.946602,
      long: 30.343172222222222,
    },
    description: '',
    magneticAngle: -92,
    gpsAccuracy: null,
    status: 'ACTIVE',
    contentCreateDate: '2023-04-05T15:01:11.000Z',
    pointId: 2,
    meta: null,
    sublineId: [],
    directions: [
      //точка перехода
      {
        contentId: 44,
        angle: -180,
      },
      //точка перехода

      /*ТОЧКИ ИНТЕРЕСА*/
      {
        contentId: 'poi-6', // фонарный столб
        angle: 35,
      },
      {
        contentId: 'poi-7', // лавочка-клумба 1
        angle: 30,
        pitch: 0.14,
      },
      {
        contentId: 'poi-8', // лавочка-клумба 2
        angle: 21,
        pitch: 0.05,
      },
      {
        contentId: 'poi-9', // лавочка-клумба 3
        angle: -97,
        pitch: 0.35,
      },
      {
        contentId: 'poi-10', // бордюр дорожного перехода
        angle: 135,
        pitch: 0.28,
      },
      {
        contentId: 'poi-11', // светофор
        angle: 145,
        pitch: 0.05,
      },
      /*ТОЧКИ ИНТЕРЕСА*/

      //точка перехода
      {
        contentId: 46,
        angle: 0,
      },
      //точка перехода
    ],
    defaultViewAngle: 0,
    viewAngle: 0,
  },
  {
    id: 46,
    src: {
      raw: '/storage/4/2023/4/45dunslg4xvrtb_raw.jpg?sk=383f6f22-6c2e-4163-a0b4-f6ab42fe8d22',
      src: '/storage/4/2023/4/45dunslg4xvrtb_src.jpg?sk=8345df0c-22a1-4067-a288-cef634d1b57e',
      tmb: '/storage/4/2023/4/45dunslg4xvrtb_tmb.jpg?sk=77bfca56-4565-4023-aa4d-924b07125579',
      tiles: {
        baseDir: '/storage/4/2023/4/45dunslg4xvrtb',
      },
    },
    type: 'PANORAMA',
    date: '2023-04-06',
    gps: {
      lat: 59.946602,
      long: 30.343366666666665,
    },
    description: '',
    magneticAngle: -94,
    gpsAccuracy: null,
    status: 'ACTIVE',
    contentCreateDate: '2023-04-05T15:02:22.000Z',
    pointId: 3,
    meta: null,
    sublineId: [],
    directions: [
      //точка перехода
      {
        contentId: 45,
        angle: -182,
      },
      //точка перехода

      /*ТОЧКИ ИНТЕРЕСА*/
      {
        contentId: 'poi-12', // фонарный столб
        angle: 100,
      },
      {
        contentId: 'poi-13', // лавочка-клумба 1
        angle: 34,
        pitch: 0.14,
      },
      {
        contentId: 'poi-14', // лавочка-клумба 2
        angle: 54,
        pitch: 0.24,
      },
      {
        contentId: 'poi-15', // лавочка-клумба 3
        angle: -147,
        pitch: 0.15,
      },
      {
        contentId: 'poi-16', // Забор ул. Чайковского
        angle: -40,
        pitch: 0.15,
      },
      /*ТОЧКИ ИНТЕРЕСА*/

      //точка перехода
      {
        contentId: 47,
        angle: 0,
      },
      //точка перехода
    ],
    defaultViewAngle: 0,
    viewAngle: 0,
  },
  {
    id: 47,
    src: {
      raw: '/storage/4/2023/4/45dunslg4xvyho_raw.jpg?sk=4cccb90c-311d-44f0-b4ff-ac0dc1cfa1da',
      src: '/storage/4/2023/4/45dunslg4xvyho_src.jpg?sk=9b790e80-4099-4a95-bab7-8c3c321cbe01',
      tmb: '/storage/4/2023/4/45dunslg4xvyho_tmb.jpg?sk=f02ec39b-0e51-40bc-96ce-bfea9a3245bf',
      tiles: {
        baseDir: '/storage/4/2023/4/45dunslg4xvyho',
      },
    },
    type: 'PANORAMA',
    date: '2023-04-06',
    gps: {
      lat: 59.946602,
      long: 30.343541666666667,
    },
    description: '',
    magneticAngle: -92,
    gpsAccuracy: null,
    status: 'ACTIVE',
    contentCreateDate: '2023-04-05T15:03:35.000Z',
    pointId: 4,
    meta: null,
    sublineId: [],
    directions: [
      //точка перехода
      {
        contentId: 46,
        angle: -178,
      },
      //точка перехода

      /*ТОЧКИ ИНТЕРЕСА*/
      {
        contentId: 'poi-17', // фонарный столб
        angle: 142,
      },
      {
        contentId: 'poi-18', // лавочка-клумба 1
        angle: 74,
        pitch: 0.24,
      },
      {
        contentId: 'poi-19', // лавочка-клумба 2
        angle: 110,
        pitch: 0.24,
      },
      {
        contentId: 'poi-20', // знак "P" рядом со светлым опелем
        angle: -147,
        pitch: 0.15,
      },
      {
        contentId: 'poi-21', // фонарный столб у забора ул. Чайковского
        angle: -82,
        pitch: -0.15,
      },
      /*ТОЧКИ ИНТЕРЕСА*/

      //точка перехода
      {
        contentId: 48,
        angle: 0,
      },
      //точка перехода
    ],
    defaultViewAngle: 0,
    viewAngle: 0,
  },
  {
    id: 48,
    src: {
      raw: '/storage/4/2023/4/45dunslg4xw55e_raw.jpg?sk=8883ad43-86fd-4990-94d2-21c0eb380aef',
      src: '/storage/4/2023/4/45dunslg4xw55e_src.jpg?sk=c77d97f0-344a-409e-b79d-83d33c2e272c',
      tmb: '/storage/4/2023/4/45dunslg4xw55e_tmb.jpg?sk=83445526-ca29-47c8-93c8-018060614a70',
      tiles: {
        baseDir: '/storage/4/2023/4/45dunslg4xw55e',
      },
    },
    type: 'PANORAMA',
    date: '2023-04-06',
    gps: {
      lat: 59.946602,
      long: 30.34371111111111,
    },
    description: '',
    magneticAngle: -92,
    gpsAccuracy: null,
    status: 'ACTIVE',
    contentCreateDate: '2023-04-05T15:04:45.000Z',
    pointId: 5,
    meta: null,
    sublineId: [],
    directions: [
      /*ТОЧКИ ИНТЕРЕСА*/
      {
        contentId: 'poi-22', // фонарный столб у забора ул. Чайковского
        angle: -143,
        pitch: -0.15,
      },
      {
        contentId: 'poi-23', // дверь в дом Effort
        angle: -50,
        pitch: -0.1,
      },
      {
        contentId: 'poi-24', // клумба 1
        angle: 132,
        pitch: 0.25,
      },
      {
        contentId: 'poi-25', // дерево в ограждении в дил
        angle: 14,
        pitch: -0.1,
      },
      {
        contentId: 'poi-26', // деревья у дороги
        angle: 27,
        pitch: -0.05,
      },
      /*ТОЧКИ ИНТЕРЕСА*/

      //точка перехода
      {
        contentId: 47,
        angle: -180,
      },
      //точка перехода
    ],
    defaultViewAngle: 0,
    viewAngle: 0,
  },
] as unknown[] as Content[];

export const panoramasContentByObjectId = {
  20: mockPanoramas,
} as const;

export const basePointsOfInterestImages = {
  'poi-1': [
    {
      // фонарный столб
      id: 24,
      originalAlt: '24 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme28_src.jpg?sk=5c60463f-18e4-446d-a2ae-0140dc233567',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme28_tmb.jpg?sk=bef70c58-2683-48f7-84b1-6f8507b1a311',
    },
    {
      id: 69,
      originalAlt: '69фото',
      original: '/storage/4/2023/6/t7u2jslied6o05_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o05_tmb.jpg',
    },
    {
      id: 49,
      originalAlt: '49фото',
      original: '/storage/4/2023/6/t7u2jslied6nlo_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nlo_tmb.jpg',
    },
  ],
  'poi-2': [
    {
      id: 70,
      originalAlt: '70фото',
      original: '/storage/4/2023/6/t7u2jslied6o0p_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o0p_tmb.jpg',
    },
    {
      //клумба рядом со столбом
      id: 41,
      originalAlt: '41 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeid_src.jpg?sk=20748801-33e6-4da3-b582-ad69c02dea9d',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeid_tmb.jpg?sk=7d3433da-fbbe-400a-912d-f00a48c979d7',
    },
    {
      id: 55,
      originalAlt: '55фото',
      original: '/storage/4/2023/6/t7u2jslied6nq8_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nq8_tmb.jpg',
    },
  ],
  'poi-3': [
    {
      id: 86,
      originalAlt: '86фото',
      original: '/storage/4/2023/6/t7u2jsliefm3zw_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3zw_tmb.jpg',
    },
    {
      id: 81,
      originalAlt: '81фото',
      original: '/storage/4/2023/6/t7u2jsliefm3wg_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3wg_tmb.jpg',
    },
    {
      id: 83,
      originalAlt: '83фото',
      original: '/storage/4/2023/6/t7u2jsliefm3xz_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3xz_tmb.jpg',
    },
    {
      id: 94,
      originalAlt: '94фото',
      original: '/storage/4/2023/6/t7u2jsliefm455_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm455_tmb.jpg',
    },
    {
      // знак "P" рядом с лексусом
      id: 30,
      originalAlt: '30 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmea6_src.jpg?sk=84459b03-ff36-4a02-88fd-da6a96ef6d71',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmea6_tmb.jpg?sk=95273aee-3f3b-40db-aea0-37ca70aa2b6a',
    },
  ],
  'poi-4': [
    {
      id: 77,
      originalAlt: '77фото',
      original: '/storage/4/2023/6/t7u2jsliefm3ti_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3ti_tmb.jpg',
    },
    {
      // бордюр дорожного перехода
      id: 40,
      originalAlt: '40 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmehl_src.jpg?sk=6dbe7417-d588-4aff-9d1c-d1322abab8d9',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmehl_tmb.jpg?sk=2b9f39b9-5075-4905-9493-622361055322',
    },
    {
      id: 95,
      originalAlt: '95фото',
      original: '/storage/4/2023/6/t7u2jsliefm45p_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm45p_tmb.jpg',
    },
  ],
  'poi-5': [
    {
      id: 66,
      originalAlt: '66фото',
      original: '/storage/4/2023/6/t7u2jslied6ny7_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6ny7_tmb.jpg',
    },
    {
      // светофор
      id: 26,
      originalAlt: '26 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme54_src.jpg?sk=eaab8ef5-27d5-4c6c-96bd-5c08c8ded8dd',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme54_tmb.jpg?sk=7adad6be-0dd4-48b3-ab28-b7bf47580aa5',
    },
  ],
  'poi-6': [
    {
      id: 80,
      originalAlt: '80фото',
      original: '/storage/4/2023/6/t7u2jsliefm3vu_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3vu_tmb.jpg',
    },
    {
      id: 76,
      originalAlt: '76фото',
      original: '/storage/4/2023/6/t7u2jsliefm3so_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3so_tmb.jpg',
    },
    {
      // фонарный стоб справа 2 панорама
      id: 25,
      originalAlt: '25 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme3u_src.jpg?sk=c1a26665-5ccd-4aa1-b117-ab8b938c0706',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme3u_tmb.jpg?sk=2fd1b2a2-4874-4642-aa6d-8304945b4754',
    },
  ],
  'poi-7': [
    {
      id: 64,
      originalAlt: '64фото',
      original: '/storage/4/2023/6/t7u2jslied6nwq_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nwq_tmb.jpg',
    },
    {
      id: 55,
      originalAlt: '55фото',
      original: '/storage/4/2023/6/t7u2jslied6nq8_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nq8_tmb.jpg',
    },
    {
      id: 63,
      originalAlt: '63фото',
      original: '/storage/4/2023/6/t7u2jslied6nw2_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nw2_tmb.jpg',
    },
    {
      //клумба 1 слева рядом со столбом 2 панорама
      id: 41,
      originalAlt: '41 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeid_src.jpg?sk=20748801-33e6-4da3-b582-ad69c02dea9d',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeid_tmb.jpg?sk=7d3433da-fbbe-400a-912d-f00a48c979d7',
    },
  ],
  'poi-8': [
    {
      id: 92,
      originalAlt: '92фото',
      original: '/storage/4/2023/6/t7u2jsliefm43x_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm43x_tmb.jpg',
    },
    {
      // лавочка-клумба 1 справа 2 панорама
      id: 36,
      originalAlt: '36 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeei_src.jpg?sk=1cb01ca5-cb79-4a26-92b3-9d1163329ecb',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeei_tmb.jpg?sk=05922bc7-68f8-4eb8-a5e2-79bd5de3a2f2',
    },
  ],
  'poi-9': [
    {
      // лавочка-клумба 2 справа 2 панорама
      id: 42,
      originalAlt: '42 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmej5_src.jpg?sk=cf9d3956-bdff-4e3e-b3e3-63173be2f1d8',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmej5_tmb.jpg?sk=f2357777-e0b2-47a7-8ae9-3b860c99f5a2',
    },
  ],
  'poi-10': [
    {
      id: 78,
      originalAlt: '78фото',
      original: '/storage/4/2023/6/t7u2jsliefm3ud_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3ud_tmb.jpg',
    },
    {
      id: 68,
      originalAlt: '68фото',
      original: '/storage/4/2023/6/t7u2jslied6nzh_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nzh_tmb.jpg',
    },
    {
      id: 65,
      originalAlt: '65фото',
      original: '/storage/4/2023/6/t7u2jslied6nxg_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nxg_tmb.jpg',
    },
    {
      id: 91,
      originalAlt: '91фото',
      original: '/storage/4/2023/6/t7u2jsliefm43b_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm43b_tmb.jpg',
    },
    {
      // бордюр дорожного перехода
      id: 40,
      originalAlt: '40 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmehl_src.jpg?sk=6dbe7417-d588-4aff-9d1c-d1322abab8d9',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmehl_tmb.jpg?sk=2b9f39b9-5075-4905-9493-622361055322',
    },
  ],
  'poi-11': [
    {
      // светофор
      id: 26,
      originalAlt: '26 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme54_src.jpg?sk=eaab8ef5-27d5-4c6c-96bd-5c08c8ded8dd',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme54_tmb.jpg?sk=7adad6be-0dd4-48b3-ab28-b7bf47580aa5',
    },
  ],
  'poi-12': [
    {
      // фонарный стоб справа 3 панорама
      id: 25,
      originalAlt: '25 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme3u_src.jpg?sk=c1a26665-5ccd-4aa1-b117-ab8b938c0706',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme3u_tmb.jpg?sk=2fd1b2a2-4874-4642-aa6d-8304945b4754',
    },
  ],
  'poi-13': [
    {
      //клумба 1 слева рядом со столбом 3 панорама
      id: 41,
      originalAlt: '41 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeid_src.jpg?sk=20748801-33e6-4da3-b582-ad69c02dea9d',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeid_tmb.jpg?sk=7d3433da-fbbe-400a-912d-f00a48c979d7',
    },
  ],
  'poi-14': [
    {
      // лавочка-клумба 1 справа 3 панорама
      id: 36,
      originalAlt: '36 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeei_src.jpg?sk=1cb01ca5-cb79-4a26-92b3-9d1163329ecb',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeei_tmb.jpg?sk=05922bc7-68f8-4eb8-a5e2-79bd5de3a2f2',
    },
  ],
  'poi-15': [
    {
      // лавочка-клумба 2 справа 3 панорама
      id: 42,
      originalAlt: '42 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmej5_src.jpg?sk=cf9d3956-bdff-4e3e-b3e3-63173be2f1d8',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmej5_tmb.jpg?sk=f2357777-e0b2-47a7-8ae9-3b860c99f5a2',
    },
  ],
  'poi-16': [
    {
      id: 52,
      originalAlt: '52фото',
      original: '/storage/4/2023/6/t7u2jslied6no8_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6no8_tmb.jpg',
    },
    {
      id: 79,
      originalAlt: '79фото',
      original: '/storage/4/2023/6/t7u2jsliefm3v1_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3v1_tmb.jpg',
    },
    {
      id: 51,
      originalAlt: '51фото',
      original: '/storage/4/2023/6/t7u2jslied6nnm_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nnm_tmb.jpg',
    },
    {
      id: 74,
      originalAlt: '74фото',
      original: '/storage/4/2023/6/t7u2jslied6o3c_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o3c_tmb.jpg',
    },
    {
      id: 60,
      originalAlt: '60фото',
      original: '/storage/4/2023/6/t7u2jslied6ntr_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6ntr_tmb.jpg',
    },

    {
      id: 58,
      originalAlt: '58фото',
      original: '/storage/4/2023/6/t7u2jslied6nsd_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nsd_tmb.jpg',
    },
    {
      // забор
      id: 38,
      originalAlt: '38 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeg3_src.jpg?sk=1244016b-033f-46b7-976d-7cc22954b0fc',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeg3_tmb.jpg?sk=a475f5e7-4269-4d18-8c01-9a781cb4985a',
    },
    {
      id: 35,
      originalAlt: '35 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmedt_src.jpg?sk=8d7d8270-6f7c-4da6-8521-f4a155601ad3',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmedt_tmb.jpg?sk=2a971804-608d-4ec5-a0fa-19e80fbe494c',
    },
  ],

  'poi-17': [
    {
      // фонарный стоб справа 4 панорама
      id: 25,
      originalAlt: '25 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme3u_src.jpg?sk=c1a26665-5ccd-4aa1-b117-ab8b938c0706',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme3u_tmb.jpg?sk=2fd1b2a2-4874-4642-aa6d-8304945b4754',
    },
  ],
  'poi-18': [
    {
      // лавочка-клумба 1 справа 4 панорама
      id: 36,
      originalAlt: '36 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmeei_src.jpg?sk=1cb01ca5-cb79-4a26-92b3-9d1163329ecb',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmeei_tmb.jpg?sk=05922bc7-68f8-4eb8-a5e2-79bd5de3a2f2',
    },
  ],
  'poi-19': [
    {
      // лавочка-клумба 2 справа 3 панорама
      id: 42,
      originalAlt: '42 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmej5_src.jpg?sk=cf9d3956-bdff-4e3e-b3e3-63173be2f1d8',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmej5_tmb.jpg?sk=f2357777-e0b2-47a7-8ae9-3b860c99f5a2',
    },
  ],
  'poi-20': [
    {
      // знак "P" рядом со светлым опелем
      id: 27,
      originalAlt: '27 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme6d_src.jpg?sk=af606ce0-beb8-4a6d-8502-1b30f9ce0566',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme6d_tmb.jpg?sk=99d291e2-1514-4dff-929c-92d24ef0b06a',
    },
  ],
  'poi-21': [
    {
      id: 71,
      originalAlt: '71фото',
      original: '/storage/4/2023/6/t7u2jslied6o1e_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o1e_tmb.jpg',
    },
    {
      id: 82,
      originalAlt: '82фото',
      original: '/storage/4/2023/6/t7u2jsliefm3x7_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3x7_tmb.jpg',
    },
    {
      id: 61,
      originalAlt: '61фото',
      original: '/storage/4/2023/6/t7u2jslied6nuj_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nuj_tmb.jpg',
    },
    {
      id: 54,
      originalAlt: '54фото',
      original: '/storage/4/2023/6/t7u2jslied6npl_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6npl_tmb.jpg',
    },
    {
      id: 85,
      originalAlt: '85фото',
      original: '/storage/4/2023/6/t7u2jsliefm3z7_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3z7_tmb.jpg',
    },
    {
      // фонарный столб у забора ул. Чайковского
      id: 34,
      originalAlt: '34 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmed2_src.jpg?sk=8782613d-cafa-4b94-be67-6de656e727ae',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmed2_tmb.jpg?sk=2c8792c1-4260-43b6-942b-2a9688015420',
    },
  ],
  'poi-22': [
    {
      id: 53,
      originalAlt: '53фото',
      original: '/storage/4/2023/6/t7u2jslied6noy_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6noy_tmb.jpg',
    },
    {
      id: 72,
      originalAlt: '72фото',
      original: '/storage/4/2023/6/t7u2jslied6o21_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o21_tmb.jpg',
    },
    {
      id: 57,
      originalAlt: '57фото',
      original: '/storage/4/2023/6/t7u2jslied6nro_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nro_tmb.jpg',
    },
    {
      // фонарный столб у забора ул. Чайковского
      id: 34,
      originalAlt: '34 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmed2_src.jpg?sk=8782613d-cafa-4b94-be67-6de656e727ae',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmed2_tmb.jpg?sk=2c8792c1-4260-43b6-942b-2a9688015420',
    },
  ],
  'poi-23': [
    {
      id: 56,
      originalAlt: '56фото',
      original: '/storage/4/2023/6/t7u2jslied6nr2_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nr2_tmb.jpg',
    },
    {
      id: 59,
      originalAlt: '59фото',
      original: '/storage/4/2023/6/t7u2jslied6nt2_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nt2_tmb.jpg',
    },
    {
      // дверь в дом Effort
      id: 29,
      originalAlt: '29 фото',
      original:
        '/storage/4/2023/4/45dunslg4xme9d_src.jpg?sk=7032f02b-3376-479a-858d-d3f55f8addce',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xme9d_tmb.jpg?sk=a86e9cf5-b71e-4269-9601-780c7d647c53',
    },
  ],
  'poi-24': [
    {
      // лавочка-клумба 2 справа 5 панорама
      id: 42,
      originalAlt: '42 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmej5_src.jpg?sk=cf9d3956-bdff-4e3e-b3e3-63173be2f1d8',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmej5_tmb.jpg?sk=f2357777-e0b2-47a7-8ae9-3b860c99f5a2',
    },
    {
      id: 75,
      originalAlt: '75фото',
      original: '/storage/4/2023/6/t7u2jsliefm3rp_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3rp_tmb.jpg',
    },
  ],
  'poi-25': [
    {
      // дерево в ограждении в дил
      id: 39,
      originalAlt: '39 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmegu_src.jpg?sk=658c513b-61bb-47c7-951c-6590b0ad373f',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmegu_tmb.jpg?sk=41d48210-4405-48d8-890b-f37f914071e0',
    },
    {
      id: 88,
      originalAlt: '88фото',
      original: '/storage/4/2023/6/t7u2jsliefm417_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm417_tmb.jpg',
    },
  ],
  'poi-26': [
    {
      id: 93,
      originalAlt: '93фото',
      original: '/storage/4/2023/6/t7u2jsliefm44j_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm44j_tmb.jpg',
    },
    {
      id: 90,
      originalAlt: '90фото',
      original: '/storage/4/2023/6/t7u2jsliefm42o_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm42o_tmb.jpg',
    },
    {
      id: 84,
      originalAlt: '84фото',
      original: '/storage/4/2023/6/t7u2jsliefm3yl_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm3yl_tmb.jpg',
    },
    {
      id: 62,
      originalAlt: '62фото',
      original: '/storage/4/2023/6/t7u2jslied6nv9_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nv9_tmb.jpg',
    },
    {
      id: 73,
      originalAlt: '73фото',
      original: '/storage/4/2023/6/t7u2jslied6o2n_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6o2n_tmb.jpg',
    },
    {
      id: 96,
      originalAlt: '96фото',
      original: '/storage/4/2023/6/t7u2jsliefm46q_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm46q_tmb.jpg',
    },
    {
      id: 89,
      originalAlt: '89фото',
      original: '/storage/4/2023/6/t7u2jsliefm41w_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm41w_tmb.jpg',
    },
    {
      id: 87,
      originalAlt: '87фото',
      original: '/storage/4/2023/6/t7u2jsliefm40j_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jsliefm40j_tmb.jpg',
    },
    {
      id: 67,
      originalAlt: '67фото',
      original: '/storage/4/2023/6/t7u2jslied6nyw_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nyw_tmb.jpg',
    },
    {
      id: 50,
      originalAlt: '50фото',
      original: '/storage/4/2023/6/t7u2jslied6nn0_src.jpg',
      thumbnail: '/storage/4/2023/6/t7u2jslied6nn0_tmb.jpg',
    },
    {
      // деревья у дороги
      id: 33,
      originalAlt: '33 фото',
      original:
        '/storage/4/2023/4/45dunslg4xmec9_src.jpg?sk=000e5737-3a75-459a-8c6b-ce6f7a02903a',
      thumbnail:
        '/storage/4/2023/4/45dunslg4xmec9_tmb.jpg?sk=58508c16-79b9-4209-bc5a-214b547ba64c',
    },
  ],
};

export const pointsOfInterestImages = Object.fromEntries(
  Object.entries(basePointsOfInterestImages).map(([key, value], index) => {
    const preparedValue = value?.map((item) => ({
      ...item,
      name: poisInfo[key].name ?? 'Точка интереса',
      description: poisInfo[key].description,
      groupDescription: poisInfo[key].groupDescription,
    }));
    return [key, preparedValue];
  }),
);
