import { TimerProps } from './timer.types';
import styles from './timer.module.scss';
import { SpanTag } from '@components/common';

import cn from 'classnames';
export const Timer = ({
  circumference,
  timerTime,
  isOn,
  size,
  center,
  radius,
  progress,
  dashArray,
  dashOffset,
  trackWidth,
  trackColor,
  indicatorWidth,
  indicatorColor,
  indicatorCap,
}: TimerProps) => {
  const timerClasses = cn(styles['timer-container'], {
    [styles['timer-small']]: size === 's',
    [styles['timer-medium']]: size === 'm',
    [styles['timer-large']]: size === 'l',
  });
  const circlesize = size === 's' ? 22 : size === 'm' ? 32 : 42;

  return (
    <div className={timerClasses}>
      <SpanTag type="text" x={3.5} weight="1">
        {String(timerTime)}
      </SpanTag>
      <svg style={{ width: circlesize, height: circlesize }} fill="none">
        <circle
          className="svg-pi-track"
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
        />
        <circle
          className={`svg-pi-indicator`}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </svg>
    </div>
  );
};
