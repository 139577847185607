import type { Stage as StageType } from 'konva/lib/Stage'

export const getViewParams = (imageParams: any, elem: any) => {
  const { width, height } = elem.getBoundingClientRect()

  // получаю относительную пропорцию ширины блока к изображению
  const sizeWidth = width / imageParams.width

  // получаю относительную пропорцию высоты блока к изображению
  const sizeHeight = height / imageParams.height

  // проверяю вписывается ли изображение в блок если выбираю пропорцию по ширине
  const isImageFitToBlockByWidth = sizeWidth * imageParams.height > height

  // в зависимости от того вписывается или нет выставляю маштабирование либо по ширине либо по высоте
  const scale = isImageFitToBlockByWidth ? sizeHeight : sizeWidth

  // перемножаю с шириной и высотой изображения
  const imageWidth = imageParams.width * scale
  const imageHeight = imageParams.height * scale

  return {
    width,
    height,
    imageWidth,
    imageHeight,
  }
}

interface IImage {
  width: number
  height: number
}

interface IPonts {
  x: number
  y: number
}

export const getPoint = (
  point: IPonts,
  schemeFrameSize: IImage,
  imageSize: IImage,
  offset = { x: 0, y: 0 },
  isMapFullscreen = false,
) => {
  const ratioX = point.x / schemeFrameSize.width
  const ratioY = point.y / schemeFrameSize.height
  const x = isMapFullscreen
    ? ratioX * imageSize.width * 0.8 + offset.x
    : ratioX * imageSize.width + offset.x
  const y = isMapFullscreen
    ? ratioY * imageSize.height * 0.8 + offset.y
    : ratioY * imageSize.height + offset.y

  return { x, y }
}

export const boundFunc = (
  pos: IPonts,
  scale: number,
  { width, height }: IImage,
) => {
  const x = Math.min(0, Math.max(pos.x, width * (1 - scale)))
  const y = Math.min(0, Math.max(pos.y, height * (1 - scale)))

  return { x, y }
}

const SCALE_THRESHOLD = 1.2
const SCALE_LIMIT = 4

export const DOT_SCALE_X = 1
export const DOT_SCALE_Y = 1

export const onWheel = (_scaleLimit: number) => (e: any) => {
  e.evt.preventDefault()
  const limit = _scaleLimit || SCALE_LIMIT
  const layer = e.currentTarget
  const stage = layer.parent
  const oldScale = layer.scaleX()
  const pointer = layer.getRelativePointerPosition()

  const mousePointTo = {
    x: (pointer.x - layer.x()) / oldScale,
    y: (pointer.y - layer.y()) / oldScale,
  }
  const newScale =
    e.evt.deltaY > 0 ? oldScale / SCALE_THRESHOLD : oldScale * SCALE_THRESHOLD
  if (newScale < 1 || newScale > limit) {
    return null
  }
  const x =
    -(mousePointTo.x - layer.getRelativePointerPosition().x / newScale) *
    newScale
  const y =
    -(mousePointTo.y - layer.getRelativePointerPosition().y / newScale) *
    newScale

  // масштабирование маркеров при зуме
  const [dotsGroup] = stage.find('#markerGroup')
  let scaleX = 0
  let scaleY = 0

  // Пропорциональное уменьшение
  const delta = 1 / newScale

  scaleX = DOT_SCALE_X * delta
  scaleY = DOT_SCALE_Y * delta

  dotsGroup.children.forEach((dot: any) => {
    dot.setAttrs({ scaleX, scaleY })
  })

  const pos = boundFunc({ x, y }, newScale, {
    width: layer.width(),
    height: layer.height(),
  })
  layer.scale({ x: newScale, y: newScale })
  return layer.position({ x: pos.x, y: pos.y })
}

export const resizeMarkers = (stage: StageType, scale = 1) => {
  const [dotsGroup] = stage.find('#markerGroup')

  // @ts-ignore
  dotsGroup?.children?.forEach((dot: any) => {
    dot.setAttrs({ scaleX: scale, scaleY: scale })
  })
}
