import styles from './object-card.module.scss';
import { ObjectCardProps } from './object-card.types';
import { CommonImage, H4Tag, SpanTag } from '../common';
import { ObjectInfo } from '../object-info';
import { getMappedGalleryObject } from '@/utils';
import { ObjectButtons } from '../object-buttons';

import classnames from 'classnames';

export const ObjectCardDesktop = ({
  object,
  editorMode = false,
  role = 'user',
  onImageClick = () => {},
  onCloseSidebar,
  flyToHandler,
}: ObjectCardProps) => {
  const photoList =
    object?.media && getMappedGalleryObject(object?.media, object.name, object.id);

  return (
    <>
      <div className={styles['card-header']}>
        <CommonImage
          image={object.thumbnail ?? undefined}
          alt={photoList && photoList[0]?.alt}
          onImgClick={() => onImageClick(object)}
          height={180}
        />
      </div>
      <div className={styles['card-info']}>
        <div className={styles['card-container']}>
          <H4Tag className={styles['card-header-title']} weight={3}>
            {object?.name}
          </H4Tag>
          <SpanTag
            className={styles['card-header-subtitle']}
            type={'caption'}
            x={3}
            weight={1}
          >
            {object?.description_of_charitable_works || ''}
          </SpanTag>
        </div>
        <div
          className={classnames(styles['card-container'], styles['card-container-info'])}
        >
          <ObjectInfo
            type="card"
            onCloseSidebar={onCloseSidebar}
            object={object}
            role={role}
            editorMode={editorMode}
          />
        </div>
        <div className={styles['card-button-container']}>
          <ObjectButtons
            flyToObjectButtonText={'Показать на карте'}
            object={object}
            editorMode={editorMode}
            flyToObject={flyToHandler}
          />
        </div>
      </div>
    </>
  );
};
