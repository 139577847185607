import type { SidebarFilterProps } from './sidebar-filter.types';

import { memo, useCallback, useState, useEffect } from 'react';

import { LineColors } from '@/constants';

import { ObjectWorkDirections, ObjectTypeDirections } from '../../___prototype';
import { Button, FilterCheckbox, FilterGroup, H3Tag } from '../common';
import { FilterBottomPanel } from './filter-bottom-panel';

import {
  useEnableObjectFiltersSelector,
  resetFilters,
  useAppDispatch,
  filterObjectsByKey,
} from '../../state';

import styles from './sidebar-filter.module.scss';
import { FilterType } from '@/types/objects-state-type';

const updateArray = (value: number, arr: number[]): number[] => {
  if (arr.includes(value)) {
    const newState = [...arr];
    newState.splice(
      arr.findIndex((el: number) => el === value),
      1,
    );
    return [...newState];
  } else {
    return [...arr, value];
  }
};

export const SidebarFilter = memo(
  ({ editorMode = false, loading = false }: SidebarFilterProps) => {
    const dispatch = useAppDispatch();
    const { workTypes, objectTypes } = useEnableObjectFiltersSelector();
    const [supportedTypes, setSupportedTypes] = useState<number[]>([]);

    const toggleSelectType = useCallback(
      <T extends keyof Pick<FilterType, 'workTypes' | 'objectTypes'>>(
        key: T,
        value: number,
      ) => {
        dispatch(
          filterObjectsByKey({
            key: key,
            value: updateArray(value, key === 'workTypes' ? workTypes : objectTypes),
          }),
        );
      },
      [workTypes.length, objectTypes.length],
    );

    const handleResetFilter = useCallback(
      <T extends keyof Pick<FilterType, 'workTypes' | 'objectTypes'>>(key: T) => {
        return () => {
          dispatch(
            filterObjectsByKey({
              key,
              value: [],
            }),
          );
        };
      },
      [],
    );

    const resetAllFilters = useCallback(() => {
      dispatch(resetFilters());
    }, []);

    useEffect(() => {
      const selected = ObjectWorkDirections.filter((el) => workTypes.includes(el.id));
      setSupportedTypes(selected.map(({ typeRestrictions }) => typeRestrictions).flat());
    }, [workTypes.length]);

    return (
      <>
        <div className={styles['content-wrapper']}>
          <div className={styles.filters}>
            <div className={styles.body}>
              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Стадия реализации объекта</H3Tag>
                  {workTypes.length > 0 && (
                    <Button
                      typeBtn="uncolored"
                      size="s"
                      className={styles['btn-clear']}
                      onClick={handleResetFilter('workTypes')}
                    >
                      Очистить
                    </Button>
                  )}
                </div>
                <FilterGroup>
                  {ObjectWorkDirections.map(({ title, value, id }) => (
                    <FilterCheckbox
                      handleChange={() => toggleSelectType('workTypes', value)}
                      key={id}
                      isChecked={workTypes.includes(value)}
                      color={LineColors[`type_${value}` as keyof typeof LineColors]}
                    >
                      {title}
                    </FilterCheckbox>
                  ))}
                </FilterGroup>
              </div>
              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Вид работ на объекте</H3Tag>
                  {objectTypes.length > 0 && (
                    <Button
                      typeBtn="uncolored"
                      size="s"
                      className={styles['btn-clear']}
                      onClick={handleResetFilter('objectTypes')}
                    >
                      Очистить
                    </Button>
                  )}
                </div>
                <FilterGroup>
                  {ObjectTypeDirections.map(({ title, value, id }) => (
                    <FilterCheckbox
                      handleChange={() => toggleSelectType('objectTypes', value)}
                      key={id}
                      isChecked={objectTypes.includes(value)}
                      disabled={
                        supportedTypes.length > 0 ? !supportedTypes.includes(id) : false
                      }
                    >
                      {title}
                    </FilterCheckbox>
                  ))}
                </FilterGroup>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom__panel}>
          <FilterBottomPanel
            editorMode={editorMode}
            onReset={resetAllFilters}
            loading={loading}
            isResetButtonActive={!!workTypes.length && !!objectTypes.length}
          />
        </div>
      </>
    );
  },
);
