import type { FilterGoupProps } from './filter-group.types';

import styles from './filter-group.module.scss';
import classnames from 'classnames';

export const FilterGroup = ({
  rowLength = 1,
  direction = 'row',
  children,
  style,
  className,
  ...props
}: FilterGoupProps): JSX.Element => {
  return (
    <ul
      className={classnames(
        styles.container,
        styles[`container-row-length-${rowLength}`],
        className,
      )}
      style={{ flexDirection: direction, ...style }}
      {...props}
    >
      {children}
    </ul>
  );
};
