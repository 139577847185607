import React, { FC } from 'react';
import { IObjectInfoProps } from './object-info.types';
import styles from './object-info.module.scss';
import { SpanTag, ToolTip } from '../common';
import { SidebarObjectInfoHeading } from '@components/sidebar/sidebar-constants';
import calendar from '@icons/new-icons/calendar.svg';
import ruler from '@icons/new-icons/ruler.svg';
import select from '@icons/new-icons/select.svg';

export const ObjectInfo: FC<IObjectInfoProps> = ({
  object,
  dividerTextLength = 40,
  onCloseSidebar,
  className = '',
  ...props
}): JSX.Element => {
  return (
    <div className={`${styles['object-info']} ${className}`} {...props}>
      <div className={styles['object-info-content']}>
        {object?.start_date && object?.end_date && (
          <ToolTip isVisible={true} content={SidebarObjectInfoHeading.dates}>
            <div className={styles['object-info-content-row']}>
              <img src={calendar} alt="calendar" />
              <SpanTag className={styles['object-info-content-value']}>
                {`${object?.start_date} — ${object?.end_date}`}
              </SpanTag>
            </div>
          </ToolTip>
        )}
        {object?.distance && (
          <ToolTip isVisible={true} content={SidebarObjectInfoHeading.distance}>
            <div className={styles['object-info-content-row']}>
              <img src={ruler} alt="ruler" />
              <SpanTag className={styles['object-info-content-value']}>
                {object?.distance} км
              </SpanTag>
            </div>
          </ToolTip>
        )}
        {object?.square && (
          <ToolTip isVisible={true} content={SidebarObjectInfoHeading.square}>
            <div className={styles['object-info-content-row']}>
              <img src={select} alt="select" />
              <SpanTag className={styles['object-info-content-value']}>
                {object?.square} M²
              </SpanTag>
            </div>
          </ToolTip>
        )}
      </div>
    </div>
  );
};
