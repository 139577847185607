export const STREETS_SOURCE_ID = 'streets';
export const STREETS_MAIN_LAYER_ID = STREETS_SOURCE_ID + '-main-layer';
export const STREETS_HOVERED_LAYER_ID = STREETS_SOURCE_ID + '-hovered-layer';
export const STREETS_HOVERED_SAFE_ZONE_LAYER_ID =
  STREETS_SOURCE_ID + '-hovered-safe-zone-layer';

export const STREETS_INTERCEPTION_SOURCE_ID = STREETS_SOURCE_ID + '-interception';
export const STREETS_INTERCEPTION_LAYER_ID = STREETS_INTERCEPTION_SOURCE_ID + '-layer';

export const STREETS_SAFE_ZONE_SOURCE_ID = STREETS_SOURCE_ID + '-safe-zone';
export const STREETS_SAFE_ZONE_LAYER_ID = STREETS_SAFE_ZONE_SOURCE_ID + '-layer';
