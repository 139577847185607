import {
  IAddDefaultSnackbarOptions,
  IAddErrorSnackbarOptions,
  IAddLoadingSnackbarOptions,
  IUpdateSnackbarOptions,
} from '@eventbus/channels/snackbar/snackbar.types'

import { snackbarEventChannel } from '@eventbus/channels'

export const addSnack = (
  options:
    | IAddDefaultSnackbarOptions
    | IAddErrorSnackbarOptions
    | IAddLoadingSnackbarOptions,
) => {
  if (!options.type) options.type = 'default'

  snackbarEventChannel.emit('addSnack', options)
}

export const updateLoadingSnack = (options: IUpdateSnackbarOptions) => {
  snackbarEventChannel.emit('updateLoadingSnack', options)
}
