import type { FC } from 'react';
import type { MarkerProps } from '../../marker.types';

import { icons } from '@/constants/icons-by-worktype';

import styles from './point-of-interest.module.scss';
import classnames from 'classnames';

export const PointOfInterestMarker: FC<MarkerProps<HTMLDivElement>> = ({
  selected,
  markerRef,
  size = 'medium',
  onMouseEnter,
  onMouseLeave,
  color,
  work_type,
  contentId,
  isVisible = true,
  object_type,
}) => {
  return (
    <div
      ref={markerRef}
      style={{
        opacity: isVisible ? 1 : 0,
      }}
      className={classnames(
        styles['poi-marker-container'],
        styles[`poi-marker-container_${size}`],
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          backgroundColor: !contentId ? '#dddddd' : color,
          pointerEvents: isVisible ? 'auto' : 'none',
        }}
        className={styles['poi-marker_dot']}
      >
        {!!object_type && icons[object_type.toString()]}
      </div>
    </div>
  );
};
