import { configureStore  } from '@reduxjs/toolkit';
import { persistConfig } from '../persist';
import { rootReducer } from '../reducer';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { monitoringApi, api, monitoringAuthApi } from '../api';


const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat([monitoringApi.middleware, api.middleware]),
  // .concat(objectApi.middleware),
});

export const persistor = persistStore(store);

