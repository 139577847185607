import { useEffect, useMemo, useState } from 'react';

import { HeaderMap } from './header-map';
import {
  useAppDispatch,
  useAppStateUserRole,
  useEditFieldSelector,
  useEditorModeStateSelector,
} from '../../../state/selector';
import {
  useSelectedObjectSelector,
  useSidebarState,
  useViewModeSelector,
} from '../../../history-state/selectors';

export const HeaderMapWrapper = ({ isScrolling = false, isLoading = false }) => {
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const role = useAppStateUserRole();
  const [viewMode, setViewMode] = useViewModeSelector();
  const selectedObject = useSelectedObjectSelector();

  useEffect(() => {
    !isSidebarOpen && setSidebarOpen(true);
  }, []);


  return (
    <HeaderMap
      isScrolling={isScrolling}
      isLoading={isLoading}
      role={role}
      view={viewMode}
      onSelectViewMode={(view) => setViewMode(view)}
      showSideBar={() => setSidebarOpen(!isSidebarOpen)}
      isSidebarOpened={isSidebarOpen}
      selectedObject={selectedObject}
    />
  );
};
