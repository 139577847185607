
export enum ContentTypes {
  aeropanorama = 'AEROPANORAMA',
  aerial = 'AERIAL',
  timelapse = 'TIMELAPSE',
  panorama = 'PANORAMA',
  image = 'IMAGE',
  video = 'VIDEO',
}

export type MonitoringContentTypes = ContentTypes;


export enum ObjectStatuses { 
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export interface AgentPlans {
  panoramas: number;
  photos: number;
  points: number;
  videos: number;
}

export interface GPS {
  lat: number;
  long: number;
}

export interface MapLayerGPS {
  bounds: number[][];
  ne: GPS;
  sw: GPS;
}

export interface MapLayerImage {
  height: number;
  src: string;
  width: number;
}

export interface TabsWithDate {
  AERIAL?: string;
  AEROPANORAMA?: string;
  PANORAMA?: string;
  TIMELAPSE?: string;
  IMAGE?: string;
  VIDEO?: string;
}

export interface Image {
  src: string;
  tmb: string;
}

export interface ObjectData {
  agentImage: null;
  agentPlans: AgentPlans;
  contentLastUpdated: string;
  createdAt: string;
  description: string;
  groupId: null;
  id: number;
  image: Image | null;
  mapLayerGPS: MapLayerGPS;
  mapLayerImage: MapLayerImage;
  mapSchemeGPS: null;
  mapSchemeImage: null;
  mergeAero: boolean;
  name: string;
  orderWeight: number;
  projectId: number;
  projects?: ProjectData[];
  lines?: LineData[];
  schemeImage: null;
  schemePoints: null;
  status: ObjectStatuses;
  tabs: ContentTypes[];
  tabsWithDate: TabsWithDate;
  updatedAt: string;
}

export interface FavouriteProject {
  id: number;
  projectId: number;
  userId: number;
}

export interface Group {
  createdAt: string;
  description: string;
  gps: GPS;
  id: number;
  image: Image | null;
  lines: LineData[];
  name: string;
  orderWeight: number;
  projectId: number;
  status: ObjectStatuses;
  updatedAt: string;
  contentLastUpdated: string;
}

export interface ProjectData {
  companies: number[];
  contentLastUpdated: string;
  description: string;
  favorite: FavouriteProject;
  gps: GPS;
  groups: Group[];
  id: number;
  image: Image | null;
  isFavorite: boolean;
  lines: LineData[];
  meta: null;
  name: string;
  orderWeight: number;
  projectStatus: ObjectStatuses;
  status: ObjectStatuses;
  updatedAt: string;
}

export interface Image {
  src: string;
  size: { with: number; height: number };
}

export interface LineData {
  id: number;
  name: string;
  description: string;
  image: Image | null;
  gps: GPS;
  createdAt: Date,
  projectId: number;
  groupId: number;
  tabs: ContentTypes[];
  tabsWithDate: TabsWithDate;
  schemeImage: Image;
  schemePoints: Record<string, any>;
  mapLayerImage: Image;
  mapLayerGPS: { nw: GPS; se: GPS };
  mapSchemeImage: Image;
  mapSchemeGPS: { nw: GPS; se: GPS };
  contentLastUpdated: string;
  status: string;
  updatedAt: Date;
}

export interface CompanyData {
  id: number;
  status: ObjectStatuses;
  name: string;
  contents: ContentTypes;
  projects: ProjectData[];
  image: Image | null;
  video: null;
  slug: string;
}

export interface ContentSrc {
  raw: string;
  src: string;
  tmb: string;
  tiles: { baseDir: string };
}

export interface Direction {
  contentId: number;
  angle: number;
}

export interface Content {
  contentCreateDate: string;
  date: string;
  defaultViewAngle: number;
  description: string;
  directions: Direction[];
  gps: GPS;
  gpsAccuracy: null;
  id: number;
  magneticAngle: number;
  meta: null;
  pointId: number;
  src: ContentSrc;
  status: ObjectStatuses;
  sublineId: any[];
  type: ContentTypes;
  viewAngle: number;
  name: string
}

export interface SelectedLineContentPayload {
  availableDates: string[];
  contentType: ContentTypes;
  data: Content[];
  date: string;
  lineID: string;
}

export interface SelectedLineContent {
  date: string;
  lineID: string;
  payload: SelectedLineContentPayload;
}

export interface Marker {
  id: number;
  magneticAngle: number;
  pointID: number;
  position: [number, number];
  viewAngle: number;
}
