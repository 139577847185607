import { Suspense, useEffect, useState, lazy, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { SidebarPointOfInterest } from '@/components';
import { HeaderMediaWrapper } from '@components/header/header-media';

import {
  useAppSelector,
  useGetBaseObjectsMutation,
  useGetProjectMutation,
  useGetProjectSelector,
} from '../../state';
import { MapLoader } from '../../components/common';
import { useBreakpointsComparison, useSearchParamsAsObject } from '../../hooks';
import { ScreenSize } from '../../constants';

import cn from 'classnames';
import styles from './media-page.module.scss';

const MediaView = lazy(() => import('../../components/view/media-view/media-view'));

export const MediaPage = (props) => {
  const [getObjects] = useGetBaseObjectsMutation();
  const { objects: { items } } = useAppSelector((state) => state);
  const [groupLines, setGroupLines] = useState([]);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  const [params, setSearchParams] = useSearchParams();
  const [{ poiID }] = useSearchParamsAsObject();

  useEffect(() => {
    !items.length && getObjects();
  }, []);

  const { projectID, groupID, contentType, objectID, contentDate, lineID, contentID } = useParams();
  const [getProject] = useGetProjectMutation();
  const project = useGetProjectSelector();


  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(contentType === 'IMAGE' ? !!contentID : !!poiID);


  useEffect(() => {
    if (projectID) {
      getProject(projectID);
    }
  }, [projectID]);


  useEffect(() => {
    if (groupID && project && !groupLines.length) {
      setGroupLines(
        project.groups.find((group) => Number(group.id) === Number(groupID)).lines,
      );
    }
  }, [contentType, groupID, project]);

  useEffect(() => {
    if (contentType === 'PANORAMA' && !poiID) {
      setIsOpened(false);
    }
  }, [poiID, contentType]);

  const handleShowSideBar = useCallback((val) => {
    if (!val) {
      params.delete('poiID');
      setSearchParams(params.toString());
    }

    setIsOpened(val);
  }, [params]);

  const mediaPageContent = useMemo(() => cn(styles['media-page-content'], {
    [styles['media-page-content__images-active']]: contentType === 'IMAGE',
  }), [contentType]);


  const onImageClick = () => {
    if (params.has('poiID')) {
      navigate(`/map/object/${objectID}/project/${projectID}/line/${lineID}/${contentDate}/IMAGE/${params.get('poiID')}`);
    }
  };

  const onImageExit = () => {
    navigate(-1);
    //handleShowSideBar(false);
    // navigate(
    //   `${LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH(
    //     String(objectID),
    //     String(projectID),
    //     String(lineID),
    //     String(contentDate),
    //     String(contentType),
    //     String(index),
    //     {},
    //     groupID,
    //   )}`,
    //   { replace: true },
    // );
  };


  return (
    <>
      <div className={styles['media-page-container']}>
        <div className={styles['content-wrapper']}>
          <HeaderMediaWrapper isOpened={isOpened} setIsOpened={setIsOpened} />
          <div className={mediaPageContent}>
            <Suspense fallback={<MapLoader />}>
              <MediaView handleShowSideBar={handleShowSideBar} isSidebarOpened={isOpened} {...props} />
            </Suspense>
          </div>
          <div
            className={
              isOpened
                ? styles['media-page-sidebar-active']
                : styles['media-page-sidebar']
            }
          >
            {!isTabletLandscape && <SidebarPointOfInterest
              showSidebar={isOpened}
              handleCloseSidebar={contentType === 'IMAGE' ? onImageExit : () => handleShowSideBar(false)}
              pointsOfInterestID={contentType === 'IMAGE' ? contentID : poiID}
              onImageBtnClick={onImageClick}
            />}
          </div>
        </div>
      </div>
    </>
  );
};
