import type { FC } from 'react';
import type { SnackbarProps } from './snackbar.types';

import { useMemo, useEffect } from 'react';

import { useTimer, useWidthMatcher } from '@/hooks';
import { SnackbarLoading } from '@components/snackbar/snackbar-types/loading';
import { SnackbarDefault } from '@components/snackbar/snackbar-types/default';
import { SnackbarError } from '@components/snackbar/snackbar-types/error';

export const SnackbarWrapper: FC<SnackbarProps> = ({
  eventName,
  type = 'default',
  withAction = false,
  handleClose,
  timerTime = 5,
  text = '',
  isMobile,
  timerSize = 's',
  actionButtonText = 'OK',
  isVisible = true,
  handleClick,
}) => {
  const { time, handleToggleTimer, refreshTimer, handleStopTimer } = useTimer({
    timerTime: timerTime * 1000,
  });

  const isMobileFromHook = useWidthMatcher(360);
  const isMobileVersion = useMemo(
    () => (isMobile !== undefined ? isMobile : isMobileFromHook),
    [isMobile, isMobileFromHook],
  );

  useEffect(() => {
    if (type === 'default' && withAction) {
      refreshTimer();
      handleToggleTimer();
    } else {
      handleStopTimer();
    }
  }, [type, text, eventName]);

  if (!isVisible) {
    return <></>;
  }

  if (type === 'error') {
    return (
      <SnackbarError
        isMobile={isMobileVersion}
        withAction={withAction}
        handleClick={handleClick}
        handleClose={handleClose}
        actionButtonText={actionButtonText}
        text={text}
      />
    );
  }

  if (type === 'loading') {
    return <SnackbarLoading isMobile={isMobileVersion} text={text} />;
  }

  return (
    <SnackbarDefault
      isMobile={isMobileVersion}
      withAction={withAction}
      timerSize={timerSize}
      timerTime={time}
      maxTime={timerTime * 1000}
      text={text}
      handleClick={handleClick}
      actionButtonText={actionButtonText}
    />
  );
};
