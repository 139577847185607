import { FC, MouseEvent, useState } from 'react';

import { useBreakpointsComparison } from '@/hooks';
import { shareObject } from '@/utils';
import { ScreenSize } from '@/constants';
import { addSnack } from '@eventbus/events/snackbar';

import { ObjectButtonsProps } from './object-buttons.types';
import { Button } from '../common';

import styles from './object-buttons.module.scss';
import classnames from 'classnames';

export const ObjectButtons: FC<ObjectButtonsProps> = ({
  flyToObjectButtonText = '',
  editorMode,
  object,
  type = 'sidebar',
  flyToObject,
}): JSX.Element | null => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  const shareButtonHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (window && object) {
      shareObject(object.id);
      addSnack({
        eventName: 'share-object-link',
        type: 'default',
        text: 'Ссылка скопирована',
      });
    }
  };

  const isShowOnMapButtonVisible = () => {
    if (!editorMode && type === 'sidebar') return true;
    return editorMode && isTablet;
  };

  if (!isShowOnMapButtonVisible()) {
    return null;
  }

  return (
    <>
      <Button
        className={styles['object-info-buttons-group-button-display']}
        elementType={'button'}
        typeBtn={'secondary'}
        children={
          !!flyToObjectButtonText
            ? flyToObjectButtonText
            : object?.is_line
            ? 'Приблизиться к элементам благоустройства'
            : 'Показать на карте'
        }
        // children={'приблизиться к точкам интереса'}
        size={type === 'sidebar' || isTablet ? 'm' : 'l'}
        showLeftIcon
        leftIcon={
          type === 'sidebar' ? (
            <i className={'icon icon-map-pin icon-tiny'} />
          ) : (
            <i className={'icon icon-map-pin icon-middle'} />
          )
        }
        onClick={flyToObject}
        style={{ gap: 4 }}
      />
      <p className={styles['object-info-buttons-group-share-container']}>
        <Button
          size={type === 'sidebar' || isTablet ? 'm' : 'l'}
          className={classnames(
            type === 'sidebar' &&
              styles['object-info-buttons-group-share-container-button'],
          )}
          elementType={'button'}
          typeBtn={'secondary'}
          showLeftIcon
          onlyIcon
          leftIcon={<i className={'icon icon-share icon-low'} />}
          onClick={shareButtonHandler}
        />
      </p>
    </>
  );
};
