import { Content } from '@/types/content.types'

export const getMiniMapViewParams = (panoramas: Content[]) => {
  if (panoramas?.length > 0) {
    return panoramas.reduce(
      (
        acc: {
          id: number
          magneticAngle: number
          viewAngle: number
          position: [number, number]
          pointID: number
          preview: string
        }[],
        cur: Content,
      ) => {
        if (
          typeof cur?.gps?.lat === 'number' &&
          typeof cur?.gps?.long === 'number'
        ) {
          acc.push({
            id: cur.id,
            magneticAngle: cur.magneticAngle,
            viewAngle: cur.viewAngle,
            position: Object.values(cur.gps) as [number, number],
            pointID: cur.pointId,
            preview: cur.src.tmb,
          })
        }

        return acc
      },
      [],
    )
  }

  return []
}
