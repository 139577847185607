import type { WindowSize } from './hooks.types'

import { useState, useEffect } from 'react'

export const useWindowSize = () => {
  const [size, setSize] = useState<WindowSize>({ width: 0, height: 0 })

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      ({
        0: {
          contentRect: { width, height },
        },
      }) => setSize({ width, height }),
    )

    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return size
}
