import type { MiniViewProps, IMarker } from './mini-view.types'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {  UISizes } from '@/types/mods.types'
import { Button } from '@/components/from-sfera'
import { ButtonTypes} from '@/components/from-sfera/button/button.types'

import { getMiniMapViewParams } from './utils'
import { MapLayer } from './map-layer'
import { SchemeLayer } from './scheme-layer'
import {
  MiniViewToCenterButton,
  MiniViewFullscreenButton,
} from './mini-view-buttons'

import './mini-view.scss'
import styles from './mini-view.module.scss'
import classnames from 'classnames'
import { useDeviceSelector, useLineFromProjectsByIdSelector } from "@/state";

export const MiniView = ({
  isScheme,
  yaw = 0,
  panoramas,
  fireEvent,
  contentID,
  isMapFullScreen,
  onSetFullScreen,
  setHovered = () => {},
  schemePoints,
  dateFrom,
  pointID,
  schemeImage,
  isFullscreen = false,
  onFullscreen,
  className,
}: MiniViewProps) => {

  const viewContainerRef = useRef<HTMLDivElement | null>(null)
  const { lineID, projectID } = useParams<{
    lineID: string
    projectID: string
  }>()
  const line = useLineFromProjectsByIdSelector(projectID, lineID)
  const [viewSize, setViewSize] = useState<string>(() => '')
  const [markers, setMarkers] = useState<IMarker[] | null>(null)
  const { isMobile, isDesktop,  isTablet } =
    useDeviceSelector()
  const toTheCenterHandlerRef = useRef<(() => void) | null>(null)


  const toggleMobileMapFullscreen = () => {
    if (isMobile && !isMapFullScreen) {
      onSetFullScreen()
    }
  }


  const onToggleMapFullscreen = useCallback(() => {
    onSetFullScreen()
    setHovered()
  }, [isMapFullScreen])

  const onMarkerClick = useCallback(
    (id: number) => {
      !!fireEvent && fireEvent(id)
    },
    [fireEvent],
  )

  useEffect(() => {
    setMarkers(getMiniMapViewParams(panoramas))
  }, [line, panoramas])

  useEffect(() => {
    if (schemeImage && schemeImage.width > schemeImage.height) {
      const screenResolutionHor =
        (schemeImage.width - schemeImage.height) / schemeImage.width
      screenResolutionHor > 0.2
        ? setViewSize(() => '_horizontal')
        : setViewSize(() => '_square')
    } else if (schemeImage) {
      const screenResolutionVer =
        (schemeImage.height - schemeImage.width) / schemeImage.width
      screenResolutionVer > 0.2
        ? setViewSize(() => '_vertical')
        : setViewSize(() => '_square')
    }
  }, [schemeImage])

  return (
    <div
      ref={viewContainerRef}
      className={classnames('mini-map', isScheme && `mini-map${viewSize}`, {
        full__active: isMapFullScreen,
        mobile: isMobile && isMapFullScreen,
        className,
      })}
    >
      <div
        className={classnames('scheme-canvas-container', 'mini-map__wrapper')}
        onClick={toggleMobileMapFullscreen}
      >
        {isDesktop && !isMapFullScreen &&  <MiniViewFullscreenButton
          isMobileOnly={false}
          isMapFullScreen={isMapFullScreen}
          onClick={onToggleMapFullscreen}
          isMobile={isMobile}
          isTablet={false}
        />}

        {!isMobile && !isMapFullScreen && isScheme && (
          <MiniViewToCenterButton
            onClick={() =>{}}
            isTablet={isTablet}
            isMapFullScreen={isMapFullScreen}
          />
        )}

        {isScheme ? (
          <SchemeLayer
            stageContainer={viewContainerRef}
            markers={panoramas}
            contentID={contentID}
            schemeImage={schemeImage}
            schemePoints={schemePoints}
            dateFrom={dateFrom}
            yaw={yaw}
            isMapFullScreen={isMapFullScreen}
            switchPan={fireEvent}
            pointID={pointID}
            isMobile={isMobile}
            onSetFullScreen={toggleMobileMapFullscreen}
            onSetSchemeCenter={toTheCenterHandlerRef}
            stage={undefined}
          />
        ) : (
          <MapLayer
            markers={markers}
            screenSize={''}
            isMapFullScreen={isMapFullScreen}
            selectedId={Number(contentID)}
            onMarkerClick={onMarkerClick}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  )
}
