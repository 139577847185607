import type { FilterType } from '../../types/objects-state-type';

import { useState, useCallback, useLayoutEffect } from 'react';

import { useAppObjects } from '../../state/selector/selector';
import { SidebarFilter } from './sidebar-filter';
import { BottomSheet } from '../common/bottom-sheet';
import { SidebarContainer } from '../common';
import {
  useSelectedObjectSelector,
  useSidebarState,
} from '../../history-state/selectors';
import { filterObjectsByKey, useAppDispatch, useDeviceSelector } from '../../state';
import {
  useEditorModeStateSelector,
  useFavoriteObjectsSelector,
  resetFilters,
} from '../../state';

const bottomIndex = 0.48;

export const SidebarFilterWrapper = () => {
  const dispatch = useAppDispatch();
  const isEditorMode = useEditorModeStateSelector();
  const objects = useAppObjects();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const selectedObjectId = useSelectedObjectSelector();
  const { isMobile } = useDeviceSelector();

  const sidebarEnabled = !loading && isSidebarOpen && !selectedObjectId;

  const resetFiltersHandler = useCallback(() => {
    dispatch(resetFilters());
  }, []);
  const onDismiss = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  useLayoutEffect(() => {
    resetFiltersHandler();
  }, [isEditorMode, dispatch]);

  useLayoutEffect(() => {
    setLoading(!(objects && objects.length > 0));
  }, [objects]);

  if (!sidebarEnabled) {
    return null;
  } else {
  }
  if (isMobile) {
    return (
      <BottomSheet
        open={sidebarEnabled}
        onDismiss={onDismiss}
        blocking={false}
        defaultSnap={({ maxHeight }: { maxHeight: number }) => maxHeight * bottomIndex}
        snapPoints={({ maxHeight }: { maxHeight: number }) => [
          maxHeight * bottomIndex,
          maxHeight,
        ]}
      >
        <SidebarFilter editorMode={isEditorMode} loading={loading} />
      </BottomSheet>
    );
  } else {
    return (
      <SidebarContainer>
        <SidebarFilter editorMode={isEditorMode} loading={loading} />
      </SidebarContainer>
    );
  }
};

export default SidebarFilterWrapper;
