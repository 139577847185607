import styles from './monitoring-view.module.scss';
import {
  useGetProjectSelector,
  useIsMonitoringOpenSelector,
} from '../../../state';
import { MonitoringViewProject } from './monitoring-view-project';
import { MonitoringViewHeader } from './monitoring-view-header';
import { PTag } from '../../common';
import { ProjectData } from '../../../types/monitoring';
import { useParams } from 'react-router-dom';
import { ViewWrapper } from '../view-wrapper';
import { formatToDate } from '../../../utils/format-date';
import { getSortedProjectByDate } from '../../../utils/get-sorted-project-by-date';
import { MonitoringViewItemsList } from './monitoring-view-items-list';
import { useState } from 'react';


export const MonitoringViewWrapper = () => {
  const isMonitoringOpen = useIsMonitoringOpenSelector();
  const { groupID } = useParams();
  const project = useGetProjectSelector();
  const [contentLastUpdated, setContentLastUpdated] = useState<string | undefined>('');


  const selectContentLines = (project: ProjectData | null, groupID: string | undefined) => {
    if (project) {
      if (groupID) {
        const group = project.groups.find(el => el.id === Number(groupID));
        if (group && group.lines) {
          const items = [...group.lines].sort((prev, cur) => prev.createdAt > cur.createdAt ? 1 : -1);

          return (
            <MonitoringViewItemsList
              contentsLastUpdated={(e) => setContentLastUpdated(e)}
              type={'line'}
              items={items}
            />
          );
        }
        return <PTag>В группе нет материалов</PTag>;
      } else {
        const items = getSortedProjectByDate(project);

        return <MonitoringViewProject items={items} />;
      }
    } else {
      return <PTag>Не найдено</PTag>;
    }
  };

  if (!isMonitoringOpen) return <></>;

  const selectedGroup = groupID && project && project.groups && project.groups.find(group => Number(group.id) === Number(groupID));

  const selectedGroupTitle = selectedGroup ? selectedGroup.name : null;

  const selectedGroupDate = selectedGroup ? formatToDate(contentLastUpdated) : null;

  return (
    <ViewWrapper>
      <div className={styles.container}>
        <MonitoringViewHeader isGroup={!!groupID} groupDate={selectedGroupDate} groupTitle={selectedGroupTitle} />
        <div className={styles['monitoring-view-project-container']}>
          {selectContentLines(project, groupID)}
        </div>
      </div>
    </ViewWrapper>
  );
};
