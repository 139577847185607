export const nationalProjects = [
  {
    value: 'Здравоохранение',
    id: 0,
  },
  {
    value: 'Демография',
    id: 1,
  },
  {
    value: 'Образование',
    id: 2,
  },
  {
    value: 'Жилье и городская среда',
    id: 3,
  },
  {
    value: 'Наука и университеты',
    id: 4,
  },
  {
    value: 'Туризм и индустрия гостеприимства',
    id: 5,
  },
  {
    value: 'Культура',
    id: 6,
  },
  {
    value: 'Экология',
    id: 7,
  },
];

interface ObjectWorkDirection {
  title: string;
  value: number;
  id: number;
  typeRestrictions: number[];
}

export const ObjectWorkDirections: ObjectWorkDirection[] = [
  {
    id: 1,
    title: 'Строительно-монтажные работы',
    value: 1,
    typeRestrictions: [2, 3, 4],
  },
  {
    id: 2,
    title: 'Проектно-изыскательские работы',
    value: 2,
    typeRestrictions: [2, 3, 4],
  },
  {
    id: 3,
    title: 'Завершенные объекты',
    value: 3,
    typeRestrictions: [1, 2, 3],
  },
];

interface ObjectTypeDirection {
  title: string;
  value: number;
  id: number;
}
export const ObjectTypeDirections: ObjectTypeDirection[] = [
  {
    id: 1,
    title: 'Благоустройство',
    value: 1,
  },
  {
    id: 2,
    title: 'Устройство наружного освещения',
    value: 2,
  },
  {
    id: 3,
    title: 'Устройство архитектурно-художественной подсветки',
    value: 3,
  },
  {
    id: 4,
    title: 'Замена светильников',
    value: 4,
  },
];
