import styles from './header-map.module.scss';
import { HeaderMapProps } from './header-map.types';
import { Button, HeaderContainer } from '../../common';
import { ViewSwitcher } from '../../view-switcher';
import { ProfileMenu } from '../../profile-menu';
import {
  useDeleteObjectSelector,
  useDeviceSelector,
  useUserProfileInfo,
} from '../../../state/selector';
import { SearchWrapper } from '../../search/search-wrapper';
import {
  setDeleteObject,
  useAppDispatch,
  useIsClusterMarkerViewSelector,
} from '../../../state';
import React from 'react';
import { useViewModeSelector } from '../../../history-state/selectors';
import logo from '@/images/logo-CPS.svg';

export const HeaderMap = ({
  children,
  showSideBar,
  isSidebarOpened,
  role,
  view,
  onSelectViewMode,
  selectedObject,
  isLoading,
  isScrolling,
}: HeaderMapProps) => {
  const user = useUserProfileInfo();
  const { isMobile } = useDeviceSelector();
  const isClusterMarkerViewSelected = useIsClusterMarkerViewSelector();
  const dispatch = useAppDispatch();
  const isDeleteObject = useDeleteObjectSelector();
  const [, setViewMode] = useViewModeSelector();

  return (
    <HeaderContainer
      isScrolling={isScrolling}
      isLoading={isLoading}
      view={view}
      isSidebarOpened={isSidebarOpened}
    >
      <div className={`${styles['content-wrapper']} ${styles['content-wrapper-start']}`}>
        {!isDeleteObject ? (
          <>
            <ProfileMenu
              src={`${process.env.REACT_APP_BACKEND_IMAGE_PATH as string}${
                user?.profile_image as string
              }`}
              name={user?.name}
              email={user?.email}
            />
            {/* <img className={styles['header-logo']} src={logo} alt="Лого" /> */}
          </>
        ) : (
          <Button
            className={styles['content-wrapper-start-button']}
            elementType={'button'}
            typeBtn={'elevation'}
            size={'l'}
            children={'Назад'}
            showLeftIcon
            leftIcon={<i className={'icon icon-arrow-1-left icon-middle'} />}
            onClick={() => {
              setViewMode('map');
              dispatch(setDeleteObject(false));
            }}
          />
        )}
      </div>
      <div className={styles['content-wrapper']}>
        <SearchWrapper />
      </div>
      <div className={styles['right-btns-wrapper']}>
        {!isDeleteObject && (
          <div
            className={`${styles['view-switcher-wrapper']} ${
              view === 'list' ? styles['view-switcher-list-view-bs'] : ''
            }`}
          >
            <ViewSwitcher role={role} view={view} onSelectViewMode={onSelectViewMode} />
          </div>
        )}
        <div
          className={`${styles['view-switcher-btn-wrapper']} ${
            view === 'list' ? styles['view-switcher-list-view-bs'] : ''
          }`}
        >
          <Button
            size={'l'}
            style={{ width: 'fit-content' }}
            onlyIcon={isSidebarOpened}
            onClick={showSideBar}
            elementType={'button'}
            typeBtn={'elevation'}
            text={
              isSidebarOpened || isMobile ? '' : selectedObject ? 'Объект' : 'Фильтры'
            }
            showRightIcon={true}
            rightIcon={
              isSidebarOpened ? (
                isMobile ? (
                  <i className="icon icon-arrow-2-down icon-middle" />
                ) : (
                  <i className="icon icon-arrow-2-right icon-middle" />
                )
              ) : isMobile ? (
                <i className="icon icon-arrow-2-up icon-middle" />
              ) : (
                <i className="icon icon-arrow-2-left icon-middle" />
              )
            }
          />
        </div>
      </div>
    </HeaderContainer>
  );
};
