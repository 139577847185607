import { Navigate, Routes, Route } from 'react-router-dom';
import { GROUP_LINE_PATH, LINE_PATH, Paths } from '../constants';
import { LoginPage, MapPage, ProfilePage } from '../pages';
import { MediaPage } from '@pages/media-page/media-page';

export const Router = ({ isAuth }: { isAuth: boolean }) => {
  if (isAuth) {
    return (
      <Routes>
        <Route path={`${Paths.MAIN_PAGE}/`} element={<MapPage />} />
        <Route path={`${Paths.MAIN_PAGE}/object/:objectID/*`} element={<MapPage />} />
        <Route path={`${Paths.MAIN_PAGE}${Paths.PROFILE}/*`} element={<ProfilePage />} />
        {[LINE_PATH, GROUP_LINE_PATH].map((item, index) => (
          <Route key={index} path={item} element={<MediaPage />}>
            <Route path={':contentDate'} element={<MediaPage />}>
              <Route path={':contentType'} element={<MediaPage />}>
                <Route path={':contentID'} element={<MediaPage />} />
              </Route>
            </Route>
          </Route>
        ))}
        <Route path={'/*'} element={<Navigate to={`${Paths.MAIN_PAGE}`} />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path={`${Paths.AUTH_PAGE}`} element={<LoginPage />} />
      <Route path={'/*'} element={<Navigate to={`${Paths.AUTH_PAGE}`} />} />
    </Routes>
  );
};
