import type { FC } from 'react'
import type { LoadingSpinnerProps } from './loading-spinner.types'

import React from 'react'
import { ReactComponent as Spinner } from './loading-spinner.svg'

import styles from './loading-spinner.module.scss'
import classnames from 'classnames'

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className }) => {
  return (
    <div className={classnames(styles['spinner-container'], className)}>
      <Spinner />
    </div>
  )
}
