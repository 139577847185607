
import { formatDate, formatToDate } from './format-date';
import type { Group, LineData, ProjectData } from '../types/monitoring';
import { SortedItems } from '../components/view/monitoring-view/monitoring-view.types';
// import {LineData, LinesToSorted} from "../types/monitoring";


// export const getSortedProjectByDate = (project: ProjectData) => {
//   const itemsDateSet = new Set([
//     ...project.groups.map(groupItem => formatDate(groupItem.createdAt)),
//     ...project.lines.map(lineItem => formatDate(lineItem.createdAt)),
//   ]);

//   const result = Array.from(itemsDateSet).map(dateItem => {
//     return {
//       date: dateItem,
//       groups: project.groups.filter(groupItem => {
//         return formatDate(groupItem.createdAt) === dateItem;
//       }),
//       lines: project.lines.filter(lineItem => {
//         return formatDate(lineItem.createdAt) === dateItem;
//       }),
//     };
//   },
//   );

//   return result;
// };

const liftUp = (arr1: any[]): any => {
  if (arr1) {
    return arr1.reduce((sum, current) =>
      Array.isArray(current) ? sum.concat(liftUp(current)) : sum.concat(current), []);
  }
};


const getCorrectDate = (value: string) => {
  const arrValue = value.split('.');

  return `${arrValue[2]} ${arrValue[1]} ${arrValue[0]}`;
};

export const getSortedProjectByDate = (project: ProjectData) => {
  if (project) {

    const arrSortedGroups = liftUp([
      ...project?.groups?.map((groupItem) => (groupItem).lines?.map((line: { contentLastUpdated: string }) => formatToDate(line.contentLastUpdated))),
    ].map((groupsDates) => groupsDates?.filter((item, i) => {
      return groupsDates?.indexOf(item) === i;
    })));

    const arrSortedLines = [
      ...project?.lines?.map((lineItem) => lineItem?.contentLastUpdated && formatToDate(lineItem?.contentLastUpdated)),
    ];

    if (arrSortedGroups || arrSortedLines) {

      const allDates = Array.from(
        new Set(
          [...arrSortedGroups, ...arrSortedLines ],
        ),
      ).sort((prev: string, cur: string) => getCorrectDate(prev) < getCorrectDate(cur) ? 1 : -1);


      const checkedGroups: number[] = [];

      const result = (allDates as string[]).map((dateItem: string) => {
        return {
          date: dateItem,
          groups: project?.groups?.filter((groupItem) => {
            const pam = groupItem?.lines?.filter((lineItem: { contentLastUpdated: string }) => {
              const lineDate = formatToDate(lineItem?.contentLastUpdated);
              if ((lineDate === dateItem) && !checkedGroups.includes(groupItem.id)) {
                // groupItem.lines.sort((prev, cur) => prev.createdAt > cur.createdAt ? 1 : -1);
                checkedGroups.push(groupItem.id); 
                return true;
              } else {
                return false;
              }
            });

            return pam.length;
          }),
          lines: project?.lines?.filter((lineItem: { contentLastUpdated: string }) => formatToDate(lineItem?.contentLastUpdated) === dateItem),

        };
      });

      return result;
    }
  }
};