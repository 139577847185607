import styles from './header-media.module.scss';
import { HeaderMediaProps } from './header-media.types';
import { Button, HeaderContainer } from '../../common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CalendarMedia } from '../../calendar-media';
import {
  LINK_MAP_OBJECT_GROUP_PATH,
  LINK_MAP_OBJECT_PATH,
  Paths,
  ScreenSize,
} from '../../../constants';
import { useBreakpointsComparison } from '../../../hooks';
import React, { useCallback } from 'react';
import logo from "@/images/logo-CPS.svg";


export const HeaderMedia = ({
                              children,
                              showSideBar,
                              isSidebarOpened = false,
                            }: HeaderMediaProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useBreakpointsComparison(ScreenSize.MP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  const { objectID, projectID, groupID, contentType } = useParams();


  const getBackLink = () => {
    if (objectID) {
      if (groupID) {
        return LINK_MAP_OBJECT_GROUP_PATH(objectID, String(projectID));
      }
      return LINK_MAP_OBJECT_PATH(objectID);
    }

    return Paths.MAIN_PAGE;
  };

  const buttonSize = () => {
    if (isMobile) return 's';
    if (isTabletLandscape) return 'm';
    return 'l';
  };

  const handleClose = useCallback(() => {
    navigate({ pathname: getBackLink() });
  }, [objectID, projectID]);

  return (
    <HeaderContainer view={'media'} isSidebarOpened={isSidebarOpened}>
      <div className={`
        ${styles.wrapper} 
        ${styles['wrapper-content-start']}
      `}>
        {
          !isTabletLandscape ? (

            <img className={styles['header-logo']} src={logo} alt="Лого" />
          ) : (
            <CalendarMedia
              location={location}
            />
          )
        }
      </div>
      {contentType !== 'IMAGE' && <div className={`
          ${styles.wrapper} 
          ${styles['wrapper-right-buttons-container']} 
        `}>
        <Button
          className={styles['wrapper-close-button']}
          size={buttonSize()}
          elementType={'button'}
          onClick={handleClose}
          typeBtn={'elevation'}
          onlyIcon={!isSidebarOpened}
          showRightIcon
          rightIcon={<i
            className={`icon icon-close-3 icon-${isTabletLandscape ? 'tiny' : 'middle'}`}></i>}
        >{isSidebarOpened ? 'Закрыть панораму' : ''}</Button>
      </div>}
    </HeaderContainer>
  );
};
