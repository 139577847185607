import type { ICenteringToMarkerOptions } from './projects-layer.types';

import { eventbus } from '@/eventbus';

export const projectLayerEventChannel = eventbus<{
  centeringToMarker: (options: ICenteringToMarkerOptions) => void;
  toPoi: (options: ICenteringToMarkerOptions) => void;
  clustering: () => void;
  unclustring: () => void;
}>();
