// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './state';
import { PersistGate } from 'redux-persist/integration/react';
import { MapProvider } from 'react-map-gl';

import 'oelp-tokens/css/variables.css';
import './index.scss';
import './fonts/inter.css';
import './bootstrap.scss';
import './fonts/icons-font/style.scss';
import './fonts/icons-font/icon-sizes.scss';
import { App, SnackbarContainer } from './components';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { history } from './utils';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HistoryRouter history={history}>
        <MapProvider>
          <App />
          <SnackbarContainer />
        </MapProvider>
      </HistoryRouter>
    </PersistGate>
  </Provider>,
);
