import { memo, useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers, Field, Form, FieldProps, FormikProps } from 'formik';
import { validationSchema } from '../../utils';
import styles from './login-view.module.scss';
import { LoginViewProps } from './login-view.types';
import { H1Tag, PasswordInput, PTag, SearchInput, Input } from '../../common';
import { Button } from '../../common/button';
import { LoginValues } from '../../../types';
import { IconProgress } from '../../sidebar-filter/progress-icon';
import logo from '../../../images/logo-CPS.svg';

export const LoginView = memo(
  ({
    error = false,
    onLoginSubmit,
    isFormDisabled = false,
    titleText = 'Добро пожаловать',
  }: LoginViewProps): JSX.Element => {
    const [initialValues, setInitialValue] = useState<LoginValues>({
      email: '',
      password: '',
    });

    const onSubmit = async (values: LoginValues, actions: FormikHelpers<LoginValues>) => {
      return onLoginSubmit(values, actions);
    };

    const checkValidateWarningMessage = (obj: Record<string, unknown>, key: string) => {
      const isWarning = !Object.keys(obj).length;
      if (isWarning) return obj[key];
      return false;
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isValid, dirty }: FormikProps<any>) => (
          <Form className={styles['login-form']}>
            <img className={styles['login-form-logo']} src={logo} alt="Лого" />
            <H1Tag weight={3}>{titleText}</H1Tag>
            <PTag weight={3} x={4}>
              Рады видеть Вас снова!
            </PTag>
            <Field name={'email'}>
              {({ field, meta }: FieldProps) => (
                <Input
                  {...field}
                  type={'email'}
                  label="Почта"
                  placeholder="Ввести почту"
                  // disabled={isFormDisabled}
                  error={!!meta.error ? meta.error : null}
                />
              )}
            </Field>
            <Field name={'password'}>
              {({ field, meta }: FieldProps) => (
                <PasswordInput
                  {...field}
                  label="Пароль"
                  placeholder="Ввести пароль"
                  // disabled={isFormDisabled}
                  error={!!meta.error ? meta.error : null}
                />
              )}
            </Field>
            <button
              type="submit"
              className={styles['login-form-button']}
              disabled={!isValid || isFormDisabled || false}
            >
              {isFormDisabled && <IconProgress stroke={'#000'} />}
              Войти
            </button>
            {!!error && <PTag className={styles['login-form-error']}>{error}</PTag>}
          </Form>
        )}
      </Formik>
    );
  },
);
