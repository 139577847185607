import { Button, SpanTag } from '@/components/common';
import { Snackbar } from '../../snackbar';
import styles from '../../snackbar.module.scss';
import { SnackbarErrorType } from '@components/snackbar/snackbar.types';

export const SnackbarError = ({
  isMobile,
  withAction,
  handleClick,
  handleClose = () => {},
  text = '',
  actionButtonText,
}: SnackbarErrorType) => {
  return (
    <Snackbar type={'error'} isMobile={isMobile} withAction={withAction}>
      <div className={styles['snackbar-content']}>
        <SpanTag weight="1" type="text" x={3.5}>
          {text}
        </SpanTag>
        <div className={styles['snackbar-action-container']}>
          {withAction && (
            <Button
              onClick={() => handleClick && handleClick()}
              size="s"
              typeBtn="elevation"
              type="button"
              color="#e9e9e9"
            >
              {actionButtonText}
            </Button>
          )}
          <Button
            onClick={handleClose}
            leftIcon={<i className="icon icon-close-3" />}
            size="s"
            typeBtn="uncolored"
            type="button"
          />
        </div>
      </div>
    </Snackbar>
  );
};
