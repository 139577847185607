import React, { useState, memo } from 'react';
import styles from './profile-menu.module.scss';
import { ProfileMenuProps } from './profile-menu.types';
import { ProfileMenuContent, ProfileMenuHeader, ProfileMenuTrigger } from './components';
import { useDropdown } from '../../hooks';


// export const ProfileMenu = ({ src = 'https://avatars.mds.yandex.net/get-zen_doc/5262161/pub_61cb1853c97cb1018f443f83_61cb43b819129b335b14c1d9/scale_1200', name = 'Name',
//   email = 'johndoe@example.com' } : ProfileMenuProps) => {
//   const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
//
//   return (
//     <Dropdown
//       className={styles['profile-menu']}
//       position={'bottom'}
//       header={<ProfileMenuHeader
//         src={src}
//         isMenuOpened={isMenuOpened}
//         name={name}
//         email={email}
//       /> }
//       body={<ProfileMenuContent  />}
//       bottomSheetHeight={400}
//       isBottomSheet={false}
//       isVisible={false}
//       onOpen={()=> setIsMenuOpened(true)}
//       onClose={() => setIsMenuOpened(false)}
//       shouldCloseOnSelect={true}
//       trigger={<ProfileMenuTrigger
//         src={src}
//         isMenuOpened={isMenuOpened}
//       />
//       } >
//     </Dropdown>
//   );
// };


export const ProfileMenu = memo(({ 
  src = 'https://avatars.mds.yandex.net/get-zen_doc/5262161/pub_61cb1853c97cb1018f443f83_61cb43b819129b335b14c1d9/scale_1200', 
  name = 'Name',
  email = 'johndoe@example.com' } : ProfileMenuProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  

  const { renderDropdown } = useDropdown({
    header: <ProfileMenuHeader
      onClick={()=> console.log(isMenuOpened)}
      src={src}
      name={name}
      email={email}
    />,
    body: <ProfileMenuContent />,
    trigger:<ProfileMenuTrigger
      src={src}
      isMenuOpened={isMenuOpened}
    />,
  });
  
  return  <div className={styles['profile-menu']}>{renderDropdown()}</div>;

});