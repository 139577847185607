import styles from './monitoring-view.module.scss';
import { MonitoringViewItem } from '../../monitoring-view-item';
import type { MonitoringViewItemsListProps } from './monitoring-view.types';
import type { LineData } from '../../../types/monitoring';


export const MonitoringViewItemsList = ({ items, type, contentsLastUpdated }: MonitoringViewItemsListProps) => {

  if (contentsLastUpdated) {
    const arrSortedLines = [
      ...items.map((lineItem) => 'contentLastUpdated' in lineItem ? lineItem.contentLastUpdated : ''),
    ].sort((prev: string | undefined, cur: string | undefined) => (prev && cur) && prev < cur ? 1 : -1);
    contentsLastUpdated(arrSortedLines[0]);
  }

  return (
    <ul className={styles['items-list']}>
      {
        items.map((groupItem) => {
          return (
            <div className={styles['items-list-wrapper']}>
              <MonitoringViewItem
                key={(groupItem as LineData ).id}
                object={groupItem}
                isGroup={type === 'group'}
                isActive={false}
              />
            </div>
          );
        })
      }
    </ul>
  );
};
