import type { Draft } from '@reduxjs/toolkit';

import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { objectApi } from '../api';
import { objectInitialState as initialState } from './initial-state';
import { ObjectInterface, Media } from '../../types';
import { loadIcons } from '../../utils';
import { FilterType } from '../../types/objects-state-type';

interface FilterValue<T extends keyof FilterType> {
  key: T;
  value: FilterType[T];
}

const objectSlice = createSlice({
  initialState,
  name: 'objects',
  reducers: {
    filterObjects: (state, { payload }: PayloadAction<FilterType>) => {
      state.filters = initialState.filters;

      state.filters = {
        ...state.filters,
        workTypes: payload.workTypes,
        objectTypes: payload.objectTypes,
      };
    },

    filterObjectsByKey: <T extends keyof FilterType>(
      state: Draft<typeof initialState>,
      { payload: { key, value } }: PayloadAction<FilterValue<T>>,
    ) => {
      state.filters = {
        ...state.filters,
        [key]: value,
      };
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },

  extraReducers: (builder) => {
    // GET OBJECTS
    builder.addMatcher(
      objectApi.endpoints.getBaseObjects.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items = payload.data.filter((el: ObjectInterface) => {
          return el.macro_region !== '0';
        });
      },
    );
    builder.addMatcher(
      objectApi.endpoints.getObjects.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items = payload.data.filter((el: ObjectInterface) => {
          return el.macro_region !== '0';
        });
      },
    );

    builder.addMatcher(
      objectApi.endpoints.addObject.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items.push(payload.data);
      },
    );

    // DELETE OBJECT
    builder.addMatcher(
      objectApi.endpoints.deleteObject.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const deleteElIdx = state.items.findIndex((el) => el.id === payload.data.id);

        if (deleteElIdx !== -1) {
          const newObjects = [...state.items];
          newObjects.splice(deleteElIdx, 1);
          (state.items as any) = [...newObjects];
        }
      },
    );

    // GET OBJECT TYPES
    builder.addMatcher(
      objectApi.endpoints.getObjectsTypes.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.types = loadIcons(payload.data);
      },
    );

    // GET OBJECTS PROGRAM
    builder.addMatcher(
      objectApi.endpoints.getObjectsProgram.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.programs = payload.data;
      },
    );

    // ADD OBJECT IMAGE
    builder.addMatcher(
      objectApi.endpoints.addObjectImage.matchFulfilled,
      (state, { meta, payload }: any) => {
        state.items.map((el: any) => {
          if (el.id === meta.arg.originalArgs.id) {
            el.media = [...el.media, payload.data];
          }
          return el;
        });
      },
    );

    // DELETE OBJECT IMAGE
    builder.addMatcher(
      objectApi.endpoints.deleteObjectImage.matchFulfilled,
      (state, { payload }: AnyAction) => {
        return state.items.forEach((el: any) => {
          if (el.id === payload.data.obj_id) {
            const index = el.media.findIndex((img: Media) => {
              return img.id === payload.data.id;
            }) as number;

            if (index !== -1) {
              const newMedia = [...el.media] as Media[];
              newMedia.splice(index, 1);
              el.media = [...newMedia];
            }
            return el;
          }
          return el;
        });
      },
    );

    // UPDATE OBJECT DATA
    builder.addMatcher(
      objectApi.endpoints.updateObjectData.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const { id, type, value } = payload.data;
        const objectIndex = state.items.findIndex((el) => Number(el.id) === Number(id));
        // if (oldObjectId !== null && oldObjectId !== undefined) {
        (state.items as any)[objectIndex][type] = value;
        // const newState = [...state.items];
        // newState.splice(oldObjectId, 1);
        // newState.push(obj);
        // state.items = newState;

        // } else {
        //   state.items = [...state.items, obj];
        // }
      },
    );

    builder.addMatcher(
      objectApi.endpoints.updateObjectCoords.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const { id, value } = payload.data;
        const objectIndex = state.items.findIndex((el) => Number(el.id) === Number(id));
        (state.items as any)[objectIndex].coords.coordinates = value;
      },
    );

    //ERROR UPDATE OBJECT DATA
    builder.addMatcher(
      objectApi.endpoints.updateObjectData.matchRejected,
      (state, { payload }: AnyAction) => {
        state.errors.updateObject = payload.status;
      },
    );
  },
});

export const { filterObjects, resetFilters, filterObjectsByKey } = objectSlice.actions;
export const { reducer: objectReducer } = objectSlice;
