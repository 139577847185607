import type { Modifier, StrictModifierNames } from 'react-popper'
import type { MapLayerProps } from './map-layer.types'
import type { PointProps } from '@/components/map/clusters/markers.types'

import { memo, useCallback, useMemo, useEffect } from 'react'
import { useMap } from 'react-map-gl'

import {
  Map as MapGL,
  MapControls,
  MapConfigs,
  MapMarker,
  MapMarkers,
  MapUtils,
  MapMarkerContent, MapZoomButtons,
} from '@/components'
import {
  Button,} from '@/components/from-sfera'
import {ButtonTypes} from '@/components/from-sfera/button/button.types'
import { ScreenSizes, UISizes } from '@/types/mods.types'
import { MAP_LAYER_MAP_ID } from '../constants'

import styles from './map-layer.module.scss'

export const MapLayer = memo<MapLayerProps>(function MapLayerMemo({
  markers,
  screenSize,
  isMapFullScreen,
  selectedId,
  onMarkerClick,
  isMobile,
}) {
  const { [MAP_LAYER_MAP_ID]: map } = useMap()

  const centeringMap = useCallback(() => {
    if (map && markers?.length) {
      map.resize()

      map.fitBounds(
        MapUtils.getPointsBounds(
          markers.map((point) => [point.position[1], point.position[0]]),
        ),
        {
          padding: isMapFullScreen ? 80 : 20,
          duration: 500,
          pitch: 0,
          bearing: 0,
        },
      )
    }

  }, [markers, map, isMapFullScreen])

  const previews = useMemo<Map<number, string>>(() => new Map(), [])
  const clusterPoints = useMemo(
    () =>
      markers?.length
        ? markers.map((el) => {
            previews.set(el.id, el.preview)
            return {
              id: el.id,
              latitude: el.position[0],
              longitude: el.position[1],
            }
          })
        : [],
    [markers],
  )

  const tooltipModifiers = useMemo<Modifier<StrictModifierNames>[]>(
    () => [
      ...MapConfigs.tooltipConfig.modifiers,
      {
        name: 'offset',
        options: {
          offset: [0, 18],
        },
      },
    ],
    [],
  )

  const renderTooltip = useCallback((id: number | number[]) => {
    if (Array.isArray(id)) {
      return null
    } else {
      return (
        <div className={styles['map-layer-marker-tooltip']}>
          <img src={previews.get(id)} alt="panorama preview" />
        </div>
      )
    }
  }, [])

  const renderPointComponent = useCallback(
    (props: PointProps) => (
      <MapMarkerContent.Panorama
        size={isMapFullScreen ? 'medium' : 'small'}
        selected={props.id === selectedId}
        {...props}
      />
    ),
    [selectedId, isMapFullScreen, map],
  )

  useEffect(() => {
    if (map && markers?.length) {
      centeringMap()
    }
  }, [isMapFullScreen, markers, map])


  return Array.isArray(markers) ? (
    <MapGL
      id={MAP_LAYER_MAP_ID}
      enableRotate={false}
      enableZooming={isMobile ? isMapFullScreen : true}
      draggable={isMobile ? isMapFullScreen : true}
      minZoom={MapConfigs.MapConstant.MIN_MINIMAP_ZOOM}
      maxZoom={MapConfigs.MapConstant.MAX_MINIMAP_ZOOM}
    >
      {!isMobile && (
          <MapControls
            position="bottom-right"
            className={styles['map-layer-panel-right-bottom_mini']}
          >
            <Button
              onClick={centeringMap}
              type={ButtonTypes.elevation}
              iconLeft={<i className={'icon-aim icon'} />}
              size={isMapFullScreen ? UISizes.medium : UISizes.small}
            />
          </MapControls>
        )
      }

      {isMapFullScreen && <MapControls position="left" className={styles['map-layer-panel-left']}>
        <MapZoomButtons mapId={MAP_LAYER_MAP_ID}/>
      </MapControls>}

      <MapMarkers
        points={clusterPoints}
        mapId={MAP_LAYER_MAP_ID}
        tooltips={isMapFullScreen && !isMobile}
        withoutTooltip={[selectedId]}
        tooltipModifiers={tooltipModifiers}
        clustered={false}
        selectedPoint={selectedId}
        withoutPointerEventsOnSelected
        renderPointComponent={renderPointComponent}
        renderTooltip={renderTooltip}
        onPointClick={onMarkerClick}
      />
    </MapGL>
  ) : null
})
