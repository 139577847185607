import { ReactElement, CSSProperties } from 'react'
import {UISizes,} from "@/types/mods.types";

export enum ButtonTypes {
  elevation = 'elevation',
  primary = 'primary',
  secondary = 'secondary',
  uncolored = 'uncolored',
  danger = 'danger',
  dangerUncolored = 'dangerUncolored',
}

export interface ButtonProps {
  children?: React.ReactNode
  iconLeft?: ReactElement | React.ReactNode
  iconRight?: ReactElement | React.ReactNode
  disabled?: boolean
  type?: ButtonTypes
  size?: UISizes
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isLoading?: boolean
  fullWidth?: boolean
  active?: boolean
  squared?: boolean
  className?: string
  contentPosition?: 'left' | 'right'
  btnType?: 'button' | 'submit' | 'reset'
  href?: string
  wrapText?: boolean
  overflowText?: 'ellipsis' | 'inherit'
  style?: CSSProperties
  isLite?: boolean
}
