import styles from './map-zoom-buttons.module.scss'
import { ButtonGroup, Button, ToolTip } from '../common'
import type { MapZoomProps } from './map-zoom-buttons.types'
//import { useMap } from '../../context'
import {useMap} from 'react-map-gl'
import { Map as MapConstants } from '../../constants/map'
import { useCallback } from "react";



export const MapZoomButtons = ({mapId}: MapZoomProps) => {
  const { [mapId]: map } = useMap()

  const zoomInHandler = useCallback(() => {
    map?.zoomIn()
  }, [map])

  const zoomOutHandler = useCallback(() => {
    map?.zoomOut()
  }, [map])


  return (
    <>
      <ButtonGroup className={styles.container} direction={'column'}>
        <Button
          onClick={zoomInHandler}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-plus icon-middle"></i>}
          disabled={map?.getZoom() === MapConstants.MAX_ZOOM}
        />
        <div className={styles['h-line']} />
        <Button
          onClick={zoomOutHandler}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-minus icon-middle"></i>}
          disabled={map?.getZoom() === MapConstants.MIN_ZOOM}
        />
      </ButtonGroup>
      {/*<ButtonGroup className={styles.container__reset} direction={'column'}>*/}
      {/*  <ToolTip*/}
      {/*    className={styles['container__reset-tooltip']}*/}
      {/*    // delayHide={10000}*/}
      {/*    isVisible*/}
      {/*    placement={'right'}*/}
      {/*    content={'Подняться к общему плану'}>*/}
      {/*    <Button*/}
      {/*      onClick={resetZoom}*/}
      {/*      elementType={'button'}*/}
      {/*      typeBtn={'uncolored'}*/}
      {/*      style={*/}
      {/*        map?.getZoom() === MapConstants.MIN_ZOOM*/}
      {/*          ? { cursor: 'not-allowed' }*/}
      {/*          : {}*/}
      {/*      }*/}
      {/*      onlyIcon*/}
      {/*      showLeftIcon={true}*/}
      {/*      leftIcon={<i className="icon icon-aim icon-middle"></i>}*/}
      {/*      disabled={map?.getZoom() === MapConstants.MIN_ZOOM}*/}
      {/*    />*/}
      {/*  </ToolTip>*/}
      {/*</ButtonGroup>*/}
    </>
  )
}
