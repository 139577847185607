import { useMemo } from 'react';

import { useWindowSize } from '@/hooks';

type WidthMatcher = number | number[] | string;

function useWidthMatcher<T>(size: T): boolean {
  const { width } = useWindowSize();

  const matchNumber = useMemo(() => {
    if (Array.isArray(size) && size.every((el) => typeof el === 'number')) {
      if (size.length === 2) {
        const sorted = size.sort((a, b) => (a as number) - (b as number));

        return width > sorted[0] && width < sorted[1];
      }
    } else if (typeof size === 'number') {
      return width < size;
    }

    return false;
  }, [width, size]);

  return matchNumber;
}

export type { WidthMatcher };
export { useWidthMatcher };
