import type { Image as ImageType } from 'konva/lib/shapes/Image'
import type { DotProps } from './dots-group.types'

import { memo, useCallback, useRef } from 'react'
import { Image } from 'react-konva'

import activeMarker from '@/icons/activeSchemaMarker.svg'
import activeMarkerBackup from '@/icons/activeSchemaMarkerBackup.svg'
import inactiveMarker from '@/icons/inactiveMarker.svg'

export const Dot = memo(function DotMemo({
  stage,
  dot,
  contentID,
  switchPan,
  yaw,
  openFull,
}: DotProps): JSX.Element {
  const dotRef = useRef<ImageType | null>(null)
  const active = contentID === Number(dot.id)

  const image = new window.Image()
  image.src = active
    ? !window.markersRotate
      ? activeMarker
      : activeMarkerBackup
    : inactiveMarker

  const onMouseEnter = useCallback(() => {
    if (dotRef) {
      stage.container().style.cursor = 'pointer'
    }
  }, [stage, dotRef.current])

  const onMouseLeave = useCallback(() => {
    stage.container().style.cursor = 'default'
  }, [stage, dotRef.current])

  return active ? (
    <Image
      ref={dotRef}
      image={image}
      x={dot.x}
      y={dot.y}
      width={openFull ? (!window.markersRotate ? 28 : 40.6769) : 28.5}
      height={!window.markersRotate ? 43 : openFull ? 30 : 23}
      offsetX={
        openFull
          ? !window.markersRotate
            ? 12
            : 20
          : !window.markersRotate
          ? 15
          : 14.25
      }
      offsetY={
        openFull
          ? !window.markersRotate
            ? 26
            : 20.5
          : !window.markersRotate
          ? 28
          : 16.25
      }
      rotation={!window.markersRotate ? 0 : yaw * (180 / Math.PI)}
    />
  ) : (
    <Image
      ref={dotRef}
      image={image}
      onClick={() => switchPan(Number(dot.id))}
      onTap={() => switchPan(Number(dot.id))}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      width={openFull ? 20 : 15}
      height={openFull ? 20 : 15}
      offsetX={8}
      offsetY={8}
      x={dot.x}
      y={dot.y}
    />
  )
})
