import type { MiniViewFullscreenButtonProps } from './mini-view-buttons.types'

import { useMemo } from 'react'

import {  Button } from '@/components/from-sfera'
import {ButtonTypes} from "@components/from-sfera/button";
import { UISizes } from '@/types/mods.types'

import styles from './mini-view-buttons.module.scss'
import classnames from 'classnames'

export const MiniViewFullscreenButton = ({
  isMobileOnly,
  isMapFullScreen,
  isMobile,
  isTablet,
  onClick,
}: MiniViewFullscreenButtonProps) => {
  return useMemo(
    () => (
      <Button
        onClick={onClick}
        type={ButtonTypes.elevation}
        className={classnames(
          styles[`minimap-btn-minimize`],
          isTablet && styles.tablet,
        )}
        iconLeft={
          isMapFullScreen ? (
            <i className={'icon icon-open-1 icon-tiny'} />
          ) : isMobileOnly ? (
            <i className={'icon icon-map icon-tiny'} />
          ) : (
            <i className={'icon icon-open-1 icon-tiny'} />
          )
        }
        size={UISizes.small}
        isLite={true}
      />
    ),
    [isMobile, isTablet, isMapFullScreen, onClick],
  )
}
