import { combineReducers  } from '@reduxjs/toolkit';
import { userApi } from '../api/user-api';
import {
  userReducer,
  mapReducer,
  deviceReducer,
  objectReducer,
  windowsReducer, 
  monitoringReducer,
} from '../slice';
import { monitoringApi, api, monitoringAuthApi } from '../api';


export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  device: deviceReducer,
  map: mapReducer,
  user: userReducer,
  objects: objectReducer,
  windows: windowsReducer,
  monitoring: monitoringReducer,
  [monitoringApi.reducerPath]: monitoringApi.reducer,
});
