import styles from './common-image.module.scss';
import classnames from "classnames";
import React from "react";
import {CommonImageProps} from '@components/common';
import emptyPhotoListImage from "@/images/object-bg-image.png";

export const CommonImage = ({
  onImgClick = () => {},
  className = '',
  type = 'card',
  alt,
  image,
  height,
  width,
}: CommonImageProps): JSX.Element => {

  const sidebarImage = image || emptyPhotoListImage
  const altContent = alt || `${type} image`

  return (
    <div
      className={classnames(styles.image, styles[`image-${type}`], className)}
      onClick={() => onImgClick()}
      style={{
        minWidth: width || 'unset',
        minHeight: height || 'unset'
      }}
    >
      <img
        src={String(sidebarImage)}
        alt={String(altContent)}
      />
    </div>
  );
};
