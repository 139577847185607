import React from 'react'
import {UISizes,} from "@/types/mods.types";
import { ButtonProps, ButtonTypes } from './button.types'
import styles from './button.module.scss'
import {
  buttonChildrenClassNames,
  buttonClassNames,
  buttonContentContainerClassNames,
} from './utils'

import classnames from 'classnames'
import { LoadingSpinner } from "@components/from-sfera";

export const Button = React.memo(
  React.forwardRef<any, ButtonProps>(
    (
      {
        children,
        iconLeft,
        iconRight,
        disabled = false,
        type = ButtonTypes.primary,
        size = UISizes.medium,
        onClick = () => {},
        isLoading = false,
        fullWidth = false,
        active = false,
        className,
        contentPosition,
        btnType,
        href,
        squared = false,
        wrapText = false,
        overflowText = 'inherit',
        style,
        isLite = false,
        ...props
      },
      ref,
    ) => {
      return href ? (
        <a
          href={href}
          className={classnames(
            buttonClassNames(
              type,
              size,
              active,
              fullWidth,
              !children || squared,
              isLite,
            ),
            styles[`button-link`],
            className && className,
          )}
          style={style}
          {...props}
        >
          <div
            className={buttonContentContainerClassNames(
              contentPosition,
              isLoading,
            )}
          >
            {iconLeft}
            {children ? (
              <div
                className={buttonChildrenClassNames(
                  wrapText,
                  overflowText,
                  size === UISizes.small,
                )}
              >
                {children}
              </div>
            ) : null}
            {iconRight}
          </div>
        </a>
      ) : (
        <button
          ref={ref}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClick(e)
          }
          disabled={disabled}
          type={btnType}
          className={classnames(
            buttonClassNames(
              type,
              size,
              active,
              fullWidth,
              !children || squared,
              isLite,
            ),
            className && className,
          )}
          style={style}
          {...{ ...props, 'data-active': active }}
        >
          <div
            className={buttonContentContainerClassNames(
              contentPosition,
              isLoading,
            )}
          >
            {iconLeft}
            {children ? (
              <div
                className={buttonChildrenClassNames(
                  wrapText,
                  overflowText,
                  size === UISizes.small,
                )}
              >
                {children}
              </div>
            ) : null}
            {iconRight}
          </div>
          {isLoading && (
            <div className={styles['button-spinner-container']}>
              <LoadingSpinner />
            </div>
          )}
        </button>
      )
    },
  ),
)
