import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './card-view.module.scss';
import { useDeleteObjectSelector, useEditorModeStateSelector } from '@/state';
import { ObjectCard } from '@/components';
import { useSelectObject } from '@hooks/depended/useSelectObject';
import { ViewWrapper } from '../view-wrapper';
import { useViewModeSelector } from '@/history-state/selectors';
import { Button } from '../../common';
import { HeaderMapWrapper } from '../../header';
import { useFilteredProtoItems } from '@/___prototype';

import { toPoi } from '@/eventbus/events/map/project-layer';

export const CardView = ({ isLoading = false }) => {
  //const objects = useFilteredObjectsSelector();
  const isEditor = useEditorModeStateSelector();
  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  //const list = getObjectPriorityList(objectPriorityList, objects);
  const [viewMode, setViewMode] = useViewModeSelector();
  const isDeleteObject = useDeleteObjectSelector();
  const cardViewContainerRef = useRef<HTMLDivElement | null>(null);
  const items = useFilteredProtoItems();

  let selectedCard = null;
  const top = useRef<number | undefined>(null);

  const [showScrollTopButton, setShowScrollTopButton] = useState<boolean>(false);

  const scrollToCard = () => {
    if (cardViewContainerRef.current && top.current) {
      cardViewContainerRef.current.scrollBy({
        top: top.current - 100,
        behavior: 'auto',
      });
    }
  };

  const handleShowScrollTopBtn = () => {
    if (cardViewContainerRef.current && cardViewContainerRef.current.parentElement) {
      cardViewContainerRef?.current.parentElement.scrollTop > 50
        ? setShowScrollTopButton(true)
        : setShowScrollTopButton(false);
    }
  };

  useEffect(() => {
    selectedCard = document.querySelector('[aria-selected="true"]');
    // @ts-ignore
    top.current = selectedCard?.getClientRects()[0]?.top;

    !isLoading && viewMode === 'list' && scrollToCard();

    if (cardViewContainerRef.current && cardViewContainerRef.current.parentElement) {
      cardViewContainerRef?.current.parentElement.addEventListener(
        'scroll',
        handleShowScrollTopBtn,
      );
    }

    return () => {
      if (cardViewContainerRef.current) {
        cardViewContainerRef?.current?.removeEventListener(
          'scroll',
          handleShowScrollTopBtn,
        );
      }
    };
  }, [viewMode, isLoading]);

  const handleScrollToTop = () => {
    if (cardViewContainerRef.current && cardViewContainerRef.current.parentElement) {
      cardViewContainerRef.current.parentElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const mappedItems = useRef<Map<string | number, any>>(new Map());

  useEffect(() => {
    // фильтруем из за того что улица может состоять из нескольких отрезков c одним и
    // тем же id, и поэтому
    // дублируются карточки объекта
    // (файл result-f.json напр id : 20 Чайковского)
    if (items.length) {
      items.map((item) => {
        if (!mappedItems.current.has(item.id)) {
          mappedItems.current.set(item.id, item);
        }
      });
    }
  }, [items]);

  const list = useMemo(() => {
    return (
      <div className={styles.container} ref={cardViewContainerRef}>
        {items.length
          ? items.map((item: any) => {
              return (
                <ObjectCard
                  key={item.id}
                  object={item}
                  editorMode={isEditor}
                  role="redactor"
                  flyToHandler={() => {
                    toPoi({ id: item?.id });
                    // setSelectedObjectId(item.id);
                    setViewMode('map');
                  }}
                  onClick={() => {
                    setSelectedObjectId(item.id);
                  }}
                  selected={selectedObjectId === item.id}
                  onCloseSidebar={() => setSelectedObjectId(null)}
                />
              );
            })
          : null}
      </div>
    );
  }, [items.length]);

  return viewMode === 'list' || isDeleteObject ? (
    <ViewWrapper>
      <HeaderMapWrapper isScrolling={showScrollTopButton} isLoading={isLoading} />
      {/* <div className={styles.container}> */}
      {list}
      {showScrollTopButton && (
        <Button
          className={styles['scroll-to-top-btn']}
          onClick={handleScrollToTop}
          elementType={'button'}
          typeBtn={'elevation'}
          showRightIcon={true}
          rightIcon={<i className="icon icon-arrow-2-up icon-middle" />}
        />
      )}
    </ViewWrapper>
  ) : null;
};
