import { URLSearchParamsInit } from 'react-router-dom'

export const batchUpdateHistory = (
  array: any,
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
      replace?: boolean | undefined
      state?: any
    }
      | undefined,
  ) => void,
): null => {
  if (!Array.isArray(array)) return null

  array.forEach(([name, value]) => {
    if (Array.isArray(value)) {
      if (value && value.length) {
        searchParams.set(name, value.toString())
      } else {
        searchParams.delete(name)
      }
    } else {
      if (value && value.length) {
        searchParams.set(name, value.toString())
      } else {
        searchParams.delete(name)
      }
    }
  })

  setSearchParams(searchParams)
  return null
}
export default batchUpdateHistory
