import { useSearchParamsAsObject } from '../hooks';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDeviceSelector } from '../state';
import { Map } from '../constants';

export const useSidebarState = () => {
  const { isMobile } = useDeviceSelector();
  const [{ isSidebarOpen = '1', ...rest }, setSearchParams] = useSearchParamsAsObject();

  const handleChangeSidebarState = useCallback(
    (value: boolean) => {
      setSearchParams({ ...rest, isSidebarOpen: value ? '1' : '0' });
    },
    [rest, setSearchParams],
  );

  return [isSidebarOpen === '1', handleChangeSidebarState] as const;
};

type ViewMode = 'map' | 'list' | 'table';
export const useViewModeSelector = (): [ViewMode, any] => {
  const [{ viewMode = 'map', ...rest }, setSearchParams] = useSearchParamsAsObject();

  const selectViewMode = useCallback((viewMode: ViewMode) => {
    switch (viewMode) {
      case 'list':
        return 'list';
      case 'table':
        return 'table';
      case 'map':
      default:
        return 'map';
    }
  }, []);

  const handleChangeViewModeState = useCallback(
    (value: ViewMode) => {
      setSearchParams({ viewMode: selectViewMode(value), ...rest });
    },
    [rest, setSearchParams],
  );

  return [selectViewMode(viewMode as ViewMode), handleChangeViewModeState];
};

export const useLatLngZoomSelector = () => {
  const [
    { lat = Map.DEFAULT_LAT, lng = Map.DEFAULT_LNG, zoom = Map.MIN_ZOOM, ...rest },
    setSearchParams,
  ] = useSearchParamsAsObject();

  const handleChangeViewModeState = useCallback(
    (lat: string, lng: string, zoom: string) => {
      setSearchParams({ lat, lng, zoom, ...rest });
    },
    [rest, setSearchParams],
  );

  return [{ lat, lng, zoom }, handleChangeViewModeState] as const;
};

export const useSelectedObjectSelector = (): number | null => {
  const { objectID } = useParams();

  return objectID ? Number(objectID) : null;
  // return useAppSelector(({ objects }) => objects.selectedItem);
};
