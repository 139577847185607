import styles from './monitoring-view.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, H4Tag, HeaderContainer } from '../../common';
import { useAppDispatch, useGetProjectSelector, useObjectByProjectIdSelector } from '../../../state/selector';
import type { MonitoringViewHeaderProps } from './monitoring-view.types';
import { Paths } from '../../../constants';
import { useSelectObject } from '../../../hooks/depended/useSelectObject';
import { setMonitoringOpen } from '../../../state';


export const MonitoringViewHeader = ({ isGroup, groupDate, groupTitle }: MonitoringViewHeaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { objectID } = useParams();
  
  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  const goBackHandler = () => {
    if (objectID) {
      navigate({ pathname: `${Paths.MAIN_PAGE}${Paths.OBJECT}/${objectID}`, search: location.search });
    }
  };
  
  //TODO: необходимо создать сохранение стейта при закрытии мониторинга, а не этот костыль тк при перезагрзке стейт теряется
  const project = useGetProjectSelector();
  const object = useObjectByProjectIdSelector(String(project?.id));
  
  const handleCloseClick = () => {
    object && setSelectedObjectId(Number(object.id));
    dispatch(setMonitoringOpen(false));
  };
  
  return (
    <HeaderContainer isSidebarOpened={true} className={styles['content-wrapper-container']}>
      <div 
        style={{ justifyContent: 'start' }}
        className={`${styles['header-wrapper']} ${styles['content-wrapper-start']}`}>
        {
          isGroup 
            ? (
              <Button
                size={'l'}
                style={{ width: 'fit-content' }}
                elementType={'button'}
                typeBtn={'elevation'}
                text={'Назад'}
                onClick={goBackHandler}
                showLeftIcon={true}
                leftIcon={<i className="icon icon-arrow-2-left icon-middle"></i>}
              />
            ) : null
        }
      </div>
      <div className={styles['header-wrapper']}>
        {
          isGroup && groupTitle
            ? (<div className={styles['info-wrapper']}>
              <H4Tag>{groupDate}</H4Tag>
                      •
              <H4Tag className={styles['info-wrapper-location']}>{groupTitle}</H4Tag>
            </div>
            ) : null
        }
      </div>
      <div className={styles['right-btns-wrapper']}>
        <div className={styles['view-switcher-wrapper']}>
        </div>
        <Button
          size={'l'}
          style={{ width: 'fit-content' }}
          onClick={handleCloseClick}
          elementType={'button'}
          typeBtn={'elevation'}
          text={'Закрыть мониторинг'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-close-3 icon-middle"></i>}
        />
      </div>
    </HeaderContainer>
  );
};
