import type { ICenteringToMarkerOptions } from '@eventbus/channels/map/layer/project/projects-layer.types';

import { projectLayerEventChannel } from '@eventbus/channels/map';

export const centeringToMarker = (options: ICenteringToMarkerOptions) =>
  projectLayerEventChannel.emit('centeringToMarker', options);
export const toPoi = (options: ICenteringToMarkerOptions) =>
  projectLayerEventChannel.emit('toPoi', options);
export const clustering = () => projectLayerEventChannel.emit('clustering');
export const unclustering = () => projectLayerEventChannel.emit('unclustring');
