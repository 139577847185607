import styles from '../profile-menu.module.scss';
import React from 'react';
import { ProfileMenuTriggerProps } from '../profile-menu.types';
import { Profile } from '../../common';


export const ProfileMenuTrigger = ({ isMenuOpened, src } : ProfileMenuTriggerProps ) => {
  return <div className={styles['trigger-avatar-container']}>
    <Profile isProfileMenuOpened={isMenuOpened} src={src}/>
  </div>;
};