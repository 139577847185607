import type { DotsGroupProps } from './dots-group.types'

import { memo } from 'react'
import { Group } from 'react-konva'

import { Dot } from './dot'

export const DotsGroup = memo(function DotsGroupMemo({
  stage,
  dots,
  contentID,
  switchPan,
  yaw,
  openFull,
}: DotsGroupProps): JSX.Element {
  if (!dots || !dots.length) {
    return <></>
  }
  return (
    <Group id="markerGroup">
      {dots.map((dot) => {
        return (
          <Dot
            openFull={openFull}
            yaw={yaw}
            dot={dot}
            stage={stage}
            contentID={Number(contentID)}
            switchPan={switchPan}
            key={dot.id}
          />
        )
      })}
    </Group>
  )
})
