import { useCallback, useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import styles from './map-page.module.scss';
import {
  clearProject,
  useAppDispatch,
  useEditorModeStateSelector,
  useGetBaseObjectsMutation,
  useGetObjectsMutation,
  useGetObjectsTypesMutation,
  useGetProjectMutation,
  useGetUserInfoMutation,
  useObjectMonitoringProjectIdSelector,
} from '@/state';
import { CardView, MapZoomButtons } from '@/components';
import bbox from '@turf/bbox';
import { debounce, throttle } from 'lodash';

import SidebarFilterWrapper from '../../components/sidebar-filter/sidebar-filter-wrapper';
import { useGetFavoriteUserItemsMutation } from '@/state';
import { useAppSelector } from '@/state';
import { HeaderMapWrapper } from '@components/header';
import { CoordsPanel } from '@components/coords-panel/coords-panel';
import { ImageGalleryMap } from '@components/image-gallery-map';
import { MapPageTypes } from './map-page.types';
import { HistorySelectedFilters } from '@components/history-components';
import { useViewModeSelector } from '@/history-state/selectors';
import { MapConfigs } from '@/components';
import { MAIN_MAP_ID } from '@/constants';
import type { MapLayerMouseEvent } from 'react-map-gl';
import { useMap } from 'react-map-gl';

import { IconProgress } from '@/components/sidebar-filter/progress-icon';
import streetsGeoJson from '@/___prototype/result-f.json';
import { MapConstant } from '@components/map/constants';
import { useSelectObject } from '@hooks/depended/useSelectObject';

import { HOVER_SAFE_ZONE } from './constants';
import {
  STREETS_SAFE_ZONE_LAYER_ID,
  STREETS_SOURCE_ID,
  STREETS_HOVERED_SAFE_ZONE_LAYER_ID,
} from './map-content/constants';

const MapContent = lazy(() =>
  import('./map-content').then((module) => ({
    default: module.MapContent,
  })),
);

const MapGL = lazy(() =>
  import('@/components').then((module) => ({
    default: module.Map,
  })),
);

const SidebarWrapper = lazy(() =>
  import('@components/sidebar/sidebar-wrapper').then((module) => ({
    default: module.SidebarWrapper,
  })),
);

export const MapPage = ({ children }: MapPageTypes): JSX.Element => {
  const [getObjects, { isLoading }] = useGetBaseObjectsMutation();
  const [getAllObjects, { isLoading: isLoadingAll }] = useGetObjectsMutation();
  const [getObjectsTypes] = useGetObjectsTypesMutation();
  const [getFavorites] = useGetFavoriteUserItemsMutation();
  const [getUserInfo] = useGetUserInfoMutation();
  const { objects, user } = useAppSelector((state) => state);
  const { items, types } = objects;
  const { favoriteItems } = user;
  const dispatch = useAppDispatch();
  const params = useParams();
  const [getProject] = useGetProjectMutation();
  const monitoringProjectId = useObjectMonitoringProjectIdSelector(params.objectID);
  const isEditorMode = useEditorModeStateSelector();
  const [viewMode] = useViewModeSelector();

  const [, handleMarkerClick] = useSelectObject();

  const mousePosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const [currZoom, setCurrZoom] = useState<'far' | 'middle' | 'close'>('far');
  const hoveredId = useRef<string | number | undefined>();
  const [hoveredStreetLine, setHoveredStreetLine] = useState<
    string | number | undefined
  >();
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (monitoringProjectId) {
      getProject(monitoringProjectId);
    } else {
      dispatch(clearProject());
    }
  }, [monitoringProjectId]);

  useEffect(() => {
    isEditorMode && getAllObjects();
  }, [isEditorMode]);

  useEffect(() => {
    !items.length && getObjects();
    !types.length && getObjectsTypes();
    !favoriteItems.length && getFavorites();
    !user.profile && getUserInfo();
  }, []);

  const { [MAIN_MAP_ID]: mapRef } = useMap();

  const handleZoom = useCallback(
    throttle((zoomLevel: number) => {
      if (zoomLevel >= MapConstant.ZOOM_TYPE_CLOSE) {
        return setCurrZoom('close');
      }

      if (
        zoomLevel > MapConstant.ZOOM_TYPE_FAR &&
        zoomLevel < MapConstant.ZOOM_TYPE_CLOSE
      ) {
        return setCurrZoom('middle');
      }

      if (zoomLevel >= MapConstant.ZOOM_TYPE_FAR) {
        return setCurrZoom('far');
      }
    }, 200),
    [mapRef],
  );

  const onHover = useCallback(
    debounce((e: MapLayerMouseEvent) => {
      if (
        Math.abs(e.point.x - mousePosition.current.x) >= HOVER_SAFE_ZONE ||
        Math.abs(e.point.y - mousePosition.current.y) >= HOVER_SAFE_ZONE
      ) {
        return;
      }

      // @ts-ignore
      if (e.target.painter.id !== STREETS_SAFE_ZONE_LAYER_ID) return;

      const feature = mapRef?.queryRenderedFeatures(e.point, {
        layers: [STREETS_SAFE_ZONE_LAYER_ID],
      })[0];

      if (mapRef && feature && hoveredId.current !== feature?.id) {
        if (hoveredId.current) {
          mapRef.setFeatureState(
            { source: STREETS_SOURCE_ID, id: hoveredId.current },
            { hover: undefined },
          );
        }
        hoveredId.current = feature.id;
        mapRef.setFeatureState(
          { source: STREETS_SOURCE_ID, id: feature.id },
          { hover: feature.id },
        );
        setHoveredStreetLine(feature.id);

        mapRef?.getCanvas().setAttribute('data-pointer-cursor', 'true');
      }
    }, 80),
    [mapRef],
  );

  const onLeave = useCallback(
    (e: MapLayerMouseEvent) => {
      if (
        hoveredId.current &&
        // @ts-ignore
        (e.target.painter.id === STREETS_SAFE_ZONE_LAYER_ID ||
          // @ts-ignore
          e.target.painter.id === STREETS_HOVERED_SAFE_ZONE_LAYER_ID)
      ) {
        mapRef?.setFeatureState(
          { source: STREETS_SOURCE_ID, id: hoveredId.current },
          { hover: false },
        );
        hoveredId.current = undefined;
        mapRef?.getCanvas().setAttribute('data-pointer-cursor', 'false');
        setHoveredStreetLine(undefined);
        onHover(e);
      }
    },
    [mapRef],
  );

  const onClick = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = mapRef?.queryRenderedFeatures(e.point, {
        layers: [STREETS_SAFE_ZONE_LAYER_ID],
      })[0];
      if (feature) {
        handleMarkerClick(feature?.properties?.object_id);

        // TODO
        // иначе при открытии сайдбара некорректное центрирование
        // нужно переделать на зависимость от сайдбара и выбранного объекта из стейтов
        setTimeout(() => {
          const coords = streetsGeoJson.features.find((el) => el.id === feature.id);

          if (coords) {
            const [minLng, minLat, maxLng, maxLat] = bbox(coords);
            mapRef?.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 40,
                duration: 500,
              },
            );
          }
        }, 100);
      }
    },
    [mapRef],
  );

  const onMapLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);

  useEffect(() => {
    if (mapRef) {
      mapRef.on('click', STREETS_SAFE_ZONE_LAYER_ID, onClick);
      mapRef.on('mouseenter', STREETS_SAFE_ZONE_LAYER_ID, onHover);
      mapRef.on('mouseleave', STREETS_SAFE_ZONE_LAYER_ID, onLeave);
      mapRef.on('load', onMapLoad);
    }

    return () => {
      mapRef?.off('click', STREETS_SAFE_ZONE_LAYER_ID, onClick);
      mapRef?.off('mouseenter', STREETS_SAFE_ZONE_LAYER_ID, onHover);
      mapRef?.off('mouseleave', STREETS_SAFE_ZONE_LAYER_ID, onLeave);
      mapRef?.off('load', onMapLoad);
    };
  }, [mapRef]);

  useEffect(() => {
    if (mapRef && hoveredStreetLine) {
      mapRef.on('mouseleave', STREETS_HOVERED_SAFE_ZONE_LAYER_ID, onLeave);

      return () => {
        mapRef?.off('mouseleave', STREETS_HOVERED_SAFE_ZONE_LAYER_ID, onLeave);
      };
    }
  }, [mapRef, hoveredStreetLine]);

  useEffect(() => {
    const test = (e: MouseEvent) => {
      mousePosition.current = { x: e.clientX, y: e.clientY };
    };

    document.addEventListener('mousemove', test);

    return () => {
      document.removeEventListener('mousemove', test);
    };
  }, []);

  return (
    <>
      {(isLoading || (isEditorMode && isLoadingAll)) && !mapLoaded && (
        <div className={styles.loader}>
          <IconProgress stroke="black" />
        </div>
      )}

      <div className={styles.container}>
        <div className={`${styles['map-wrapper']} map-container`}>
          {viewMode === 'map' && <HeaderMapWrapper isLoading={isLoading} />}
          {children}
          <Suspense fallback={'Loading...'}>
            <MapGL
              maxBounds={MapConstant.MAX_BOUNDS}
              id={MAIN_MAP_ID}
              onZoom={(e) => {
                handleZoom(e.viewState.zoom);
              }}
              onClick={onClick}
              minZoom={MapConfigs.MapConstant.MIN_ZOOM}
              maxZoom={MapConfigs.MapConstant.MAX_ZOOM}
              enableRotate
            >
              <MapContent hovered={hoveredStreetLine} mapZoom={currZoom} />
            </MapGL>
          </Suspense>
          {viewMode === 'map' && <MapZoomButtons mapId={MAIN_MAP_ID} />}
          <CardView isLoading={isLoading} />
          <CoordsPanel />
          <ImageGalleryMap />
          <HistorySelectedFilters />
        </div>
        <SidebarFilterWrapper />
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route path={'/*'} element={<SidebarWrapper />}>
              <Route path={'group/:groupID'} element={<SidebarWrapper />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </>
  );
};
