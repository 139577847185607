import { memo } from 'react';

import { Checkbox } from '../checkbox';
import { ToolTip } from '../tooltip';
import type { FilterCheckboxProps } from './filter-checkbox.types';

import styles from './filter-checkbox.module.scss';

import classnames from 'classnames';

export const FilterCheckbox = memo<FilterCheckboxProps>(
  ({
    handleChange,
    isChecked = false,
    children,
    onClick,
    style,
    disabled = false,
    className = '',
    color,
    icon,
  }): JSX.Element => {
    return (
      <li
        onClick={disabled ? undefined : onClick}
        className={classnames(
          styles.container,
          disabled && styles.container_disabled,
          className,
        )}
      >
        <ToolTip
          className={styles['container-tooltip']}
          // isVisible={typeof children === 'string' && children.length > 43}
          isVisible={false}
          content={children}
          toolTipMaxWidth={400}
        >
          <label
            className={classnames(styles.label, isChecked && styles.activeLabel)}
            style={style}
          >
            {color && (
              <div className={styles['label-icon']} style={{ background: color }} />
            )}
            {icon && <div className={styles['left-icon']}>{icon}</div>}

            <Checkbox
              name={children}
              checked={isChecked}
              onChange={disabled ? undefined : handleChange}
              className={styles['label-checkbox']}
            />
          </label>
        </ToolTip>
      </li>
    );
  },
  (prevState, newState): boolean => {
    if (prevState.isChecked !== newState.isChecked) return false;
    if (prevState.handleChange !== newState.handleChange) return false;
    return true;
  },
);
