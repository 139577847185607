import queryString from 'query-string';
import { deleteEmptyKeysFromObject } from '../utils/deleteEmptyKeysFromObject';
import { Map as MapConstants } from '../constants';

type IPathProp = number | string;
type IPathPropNUll = number | string | null;

// base contants

export enum Paths {
  MAIN_PAGE = '/map',
  OBJECT = '/object',
  PROJECT = '/project',
  GROUP = '/group',
  LINE = '/line',
  OBJECT_LIST_PAGE = '/list',
  OBJECT_TABLE_PAGE = '/table',
  EDIT_PAGE = '/edit',
  LINK_SUBSIDIARY_PATH = '/map/:subsidiaryID',
  LINK_SUBSIDIARY_DEPARTMENT_PATH = '/map/:subsidiaryID/:departmentID',
  LINK_SUBSIDIARY_DEPARTMENT_OBJECT_PATH = '/map/:subsidiaryID/:departmentID/:objectID',
  AUTH_PAGE = '/enter',
  AUTH_MONITORING_PAGE = '/login',
  PROFILE = '/profile',
  PROFILE_FAVORITES = '/profile/favorites',
  MEDIA_PAGE = '/media',
  MONITORING_PAGE = '/monitoring',
  MONITORING_GROUP_PAGE = '/group',
  PANORAMA = '/panorama',
}

export enum MediaTypes {
  VIDEO = 'VIDEO',
  PHOTO = 'IMAGE',
  IMAGES = 'IMAGE',
  PANORAMA = 'PANORAMA',
}

export const LINK_MAP_PATH = Paths.MAIN_PAGE;
// export const LINK_MAP_OBJECT_SEARCH_PARAMS_PATH = (coords: [number, number]) => `lat=${String(coords[0])}&lng=${String(coords[1])}&zoom=${MapConstants.FLY_TO_ZOOM_VALUE}&pointID=1&isSidebarOpen=1&monitoring=1`;
// export const LINK_MAP_OBJECT_PATH = (objectID : number | string) =>  `${LINK_MAP_PATH}${Paths.OBJECT}/${String(objectID)}`;
// export const LINK_MAP_OBJECT_GROUP_PATH = (objectID : number | string, groupID: number | string) =>  `${LINK_MAP_OBJECT_PATH(objectID)}${Paths.GROUP}/${String(groupID)}`;
// router constants

// auth and profile
export const AUTH_PATH = Paths.AUTH_PAGE;
export const AUTH_MONITORING_PATH = Paths.AUTH_MONITORING_PAGE;
export const PROFILE_PATH = Paths.PROFILE;
// map, object
export const MAIN_PATH = Paths.MAIN_PAGE;
export const OBJECT_PATH = `${MAIN_PATH}${Paths.OBJECT}/:objectID`;
export const GROUP_PATH = `${OBJECT_PATH}${Paths.GROUP}/:groupID`;
// monitoring paths
export const PROJECT_PATH = `${OBJECT_PATH}${Paths.PROJECT}/:projectID`;
export const LINE_PATH = `${PROJECT_PATH}${Paths.LINE}/:lineID`;
export const GROUP_LINE_PATH = `${GROUP_PATH}${Paths.LINE}/:lineID`;
export const LINE_PATH_MAYBE_CONTENT = `${LINE_PATH}/:contentDate?/:contentType?/:contentID?`;
export const GROUP_LINE_PATH_MAYBE_CONTENT = `${GROUP_LINE_PATH}/:contentDate?/:contentType?/:contentID?`;
export const LINE_DATE_PATH = `${LINE_PATH}/:contentDate`;
export const GROUP_LINE_DATE_PATH = `${GROUP_LINE_PATH}/:contentDate`;
export const LINE_DATE_TYPE_PATH = `${LINE_DATE_PATH}/:contentType/:contentID?`;
export const GROUP_LINE_DATE_TYPE_PATH = `${GROUP_LINE_DATE_PATH}/:contentType/:contentID?`;
export const LINE_DATE_TYPE_CONTENT_PATH = `${LINE_DATE_PATH}/:contentType/:contentID`;
export const GROUP_LINE_DATE_TYPE_CONTENT_PATH = `${GROUP_LINE_DATE_PATH}/:contentType/:contentID`;

// route methods

export const LINK_MAIN_PATH = Paths.MAIN_PAGE;

export const LINK_MAP_OBJECT_SEARCH_PARAMS_PATH = (coords: number[]) =>
  `lat=${String(coords[0])}&lng=${String(coords[1])}&zoom=${
    MapConstants.FLY_TO_ZOOM_VALUE
  }&pointID=1&isSidebarOpen=1&monitoring=1`;

export const LINK_MAP_OBJECT_PATH = (objectID: IPathProp) =>
  `${Paths.MAIN_PAGE}${Paths.OBJECT}/${String(objectID)}`;

export const LINK_PROJECT_PATH = (objectID: IPathProp, projectID: IPathProp) =>
  `${LINK_MAP_OBJECT_PATH(objectID)}${Paths.PROJECT}/${projectID}`;

export const LINK_MAP_OBJECT_GROUP_PATH = (objectID: IPathProp, groupID: IPathProp) =>
  `${LINK_MAP_OBJECT_PATH(objectID)}${Paths.GROUP}/${groupID}`;

export const LINK_LINE_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  lineID: IPathProp,
  groupID?: IPathPropNUll,
) =>
  groupID
    ? `${LINK_PROJECT_PATH(objectID, projectID)}${Paths.GROUP}/${groupID}${
        Paths.LINE
      }/${lineID}`
    : `${LINK_PROJECT_PATH(objectID, projectID)}${Paths.LINE}/${lineID}`;

export const LINK_GROUP_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  groupID: IPathProp,
) => `${LINK_PROJECT_PATH(objectID, projectID)}${Paths.GROUP}/${groupID}`;

export const LINK_LINE_DATE_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  lineID: IPathProp,
  date: IPathProp,
  groupID?: string | number | undefined | null,
) => `${LINK_LINE_PATH(objectID, projectID, lineID, groupID)}/${date}`;

export const LINK_LINE_DATE_TYPE_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  lineID: IPathProp,
  date: IPathProp,
  contentType: IPathProp,
  groupID?: string | number | undefined | null,
) => `${LINK_LINE_DATE_PATH(objectID, projectID, lineID, date, groupID)}/${contentType}`;

export const LINK_LINE_DATE_TYPE_CONTENT_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  lineID: IPathProp,
  date: IPathProp,
  contentType: IPathProp,
  contentID: IPathProp,
  groupID?: string | number | undefined | null,
) =>
  `${LINK_LINE_DATE_TYPE_PATH(
    objectID,
    projectID,
    lineID,
    date,
    contentType,
    groupID,
  )}/${contentID}`;

export const LINK_GROUP_LINE_PATH = (objectID: IPathProp, groupID: IPathProp) =>
  `${LINK_MAP_OBJECT_PATH(objectID)}${Paths.GROUP}/${groupID}`;

export const LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH = (
  objectID: IPathProp,
  projectID: IPathProp,
  lineID: IPathProp,
  date: IPathProp,
  contentType: string,
  contentID: IPathProp,
  params: { [p: string]: string | number | boolean },
  groupID?: string | number | undefined | null,
) => {
  return `${LINK_LINE_DATE_TYPE_CONTENT_PATH(
    objectID,
    projectID,
    lineID,
    date,
    contentType,
    contentID,
    groupID,
  )}${params ? `?${queryString.stringify(deleteEmptyKeysFromObject(params))}` : ''}`;
};
