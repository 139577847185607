import React, { FC, memo } from 'react';
import { HeaderMedia } from './header-media';
import { HeaderMediaWrapperProps } from './header-media.types';


export const HeaderMediaWrapper: FC<HeaderMediaWrapperProps> = memo(({ setIsOpened, isOpened }) => {

  const handleShowSideBar = (val: boolean) => {
    setIsOpened(val);
  };

  return <HeaderMedia showSideBar={handleShowSideBar} isSidebarOpened={isOpened} />;
});
