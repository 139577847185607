export const initialState = () => ({
  load: true,
  img: null,
  dots: [],
  stageView: {
    width: 200,
    height: 200,
  },
})

const LOADING = 'LOADING'
export const setLoading = (load: boolean) => ({ type: LOADING, load })
const LOAD_IMAGE = 'LOAD_IMAGE'
export const loadImage = (img: HTMLImageElement) => ({ type: LOAD_IMAGE, img })
const LOAD_DOTS = 'LOAD_DOTS'
export const loadDots = (dots: any) => ({ type: LOAD_DOTS, dots })
const SET_STAGE_VIEW = 'SET_STAGE_VIEW'
export const setStageView = (stageView: any) => ({
  type: SET_STAGE_VIEW,
  stageView,
})

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, load: action.load }
    case LOAD_IMAGE:
      return { ...state, img: action.img }
    case LOAD_DOTS:
      return { ...state, dots: [...action.dots] }
    case SET_STAGE_VIEW:
      return { ...state, stageView: { ...action.stageView } }
    default:
      return state
  }
}
