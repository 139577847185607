import type { ZoomButtonsBlockProps } from './zoom-buttons-block.types'

import {ButtonGroup, Button} from "@components/common";
import {ZoomScale} from "@/components";
import styles from './zoom-buttons-block.module.scss'
import classnames from 'classnames'

export const ZoomButtonsBlock = ({
  zoomInHandler,
  zoomOutHandler,
  zoomInDisabled = false,
  zoomOutDisabled = false,
  className = '',
  type = 'mini',
  zoom = 0,
  onSlideZoom = () => {},
}: ZoomButtonsBlockProps) => {


  return (
    <ButtonGroup
     direction={'column'}
     className={classnames(
       styles['zoom-button-block'],
       type === 'mini' && styles['zoom-button-block_with-gap'],
       className,
     )}
    >
      <Button
        elementType={'button'}
        typeBtn={'uncolored'}
        showLeftIcon={true}
        leftIcon={<i className="icon icon-plus icon-middle"></i>}
        onClick={zoomInHandler}
        disabled={zoomInDisabled}
      />

      {type === 'default' ? (
        <ZoomScale
          defaultZoom={zoom}
          max={150}
          min={10}
          onSlideZoom={onSlideZoom}
          reverse
          multiplicity={100}
        />
      ) : (
        <div className={styles['zoom-button-block-divider']} />
      )}

      <Button
        elementType={'button'}
        typeBtn={'uncolored'}
        showLeftIcon={true}
        leftIcon={<i className="icon icon-minus icon-middle"></i>}
        onClick={zoomOutHandler}
        disabled={zoomOutDisabled}
      />
    </ButtonGroup>
  )
}
