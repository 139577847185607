import { useState, useEffect, useMemo } from 'react';
import {
  useSelectedFilterObjectDirections,
  useSelectedFilterWorkDirections,
} from '../state';
import { items as itemsData } from './items';
import { ObjectTypeDirections, ObjectWorkDirections } from './program';

export const useFilteredProtoItems = () => {
  const objectTypes = useSelectedFilterObjectDirections();
  const worksTypes = useSelectedFilterWorkDirections();
  const uniqItems = useMemo(() => {
    const uniqueValues = new Map();
    itemsData.forEach((el) => uniqueValues.set(el.id, el));
    return [...uniqueValues.values()];
  }, []);

  const [items, setItems] = useState<typeof itemsData>([]);

  useEffect(() => {
    if (worksTypes.length || objectTypes.length) {
      const works = new Set(
        worksTypes.length ? worksTypes : ObjectWorkDirections.map(({ value }) => value),
      );
      const objects = new Set(
        objectTypes.length ? objectTypes : ObjectTypeDirections.map(({ value }) => value),
      );

      const tmp = uniqItems.filter((el) => {
        if (works.has(el.work_type)) {
          if (objectTypes.length > 0) {
            if (objects.has(el.object_type)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      });

      setItems(tmp);
    } else {
      setItems(uniqItems);
    }
  }, [worksTypes.length, objectTypes.length]);

  return items;
};
