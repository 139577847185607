import styles from './header-container.module.scss';
import { HeaderContainerProps } from './header-container.types';
import cn from 'classnames';
import React from 'react';

export const HeaderContainer = ({
  isSidebarOpened,
  children,
  className = '',
  view,
  isLoading,
  isScrolling,
}: HeaderContainerProps) => {
  const rightBtnsClasses = cn(styles['header-container'], {
    [`${styles.opened}`]: isSidebarOpened,
  });

  const sidebarContainerClasses = cn(styles['header-container'], className, {
    [styles['header-list-view-bg']] :  view === 'list' && isScrolling,
    [styles['header-container-sidebar-active']] : view === 'media' && isSidebarOpened
  })

  return (
    <header
      className={sidebarContainerClasses}
    >
      {children}
    </header>
  );
};
