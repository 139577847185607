import type { ReactNode } from 'react';

import { ReactComponent as Lamp } from '@/icons/prototype/lamp.svg';
import { ReactComponent as Fluorescent } from '@/icons/prototype/street.svg';
import { ReactComponent as Park } from '@/icons/prototype/park.svg';
import { ReactComponent as Light } from '@/icons/prototype/light.svg';

export const icons: Record<string, ReactNode> = {
  '1': <Park />,
  '2': <Lamp />,
  '3': <Fluorescent />,
  '4': <Lamp />,
  '5': <Fluorescent />,
  '6': <Lamp />,
  '7': <Fluorescent />,
  '8': <Light />,
  '9': <Light />,
};
