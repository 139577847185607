import { IconProgress } from '@/components/sidebar-filter/progress-icon';
import { Snackbar } from '../../snackbar';
import styles from '../../snackbar.module.scss';

import { SnackbarLoadingType } from '@components/snackbar/snackbar.types';
import { SpanTag } from '../../../common';

export const SnackbarLoading = ({ text = '', isMobile }: SnackbarLoadingType) => {
  return (
    <Snackbar type={'loading'} isMobile={isMobile}>
      <div className={styles['snackbar-content']}>
        <div className={styles['snackbar-action-container']}>
          <div className={styles['snackbar-spinner']}>
            <IconProgress stroke="#67686b" />
          </div>
          <SpanTag weight="1" type="text" x={3.5} color="#e9e9e9">
            {text}
          </SpanTag>
        </div>
      </div>
    </Snackbar>
  );
};
