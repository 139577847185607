export enum UISizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum InputSizes {
  medium = 'medium',
  large = 'large',
}

export enum ObjectsSortTypes {
  name = 'name',
  date = 'date',
  buildings = 'buildings',
}

export enum ObjectsFilterTypes {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum SuggestionsSearchFilter {
  projects = 'projects',
  address = 'address',
  group = 'group',
  line = 'line',
}


export enum ScreenSizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
  XXXL = 'XXXL',
}


export enum ButtonBlockOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface ButtonBlockProps {
  children: React.ReactNode
  orientation?: ButtonBlockOrientation
  withGap?: boolean
  className?: string
  small?: boolean
}
