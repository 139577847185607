import type { MonitoringViewProjectProps, SortedItems } from './monitoring-view.types';
import { MonitoringViewItemsList } from './monitoring-view-items-list';
import { H3Tag } from '../../common';


export const MonitoringViewProject = ({ items }: MonitoringViewProjectProps) => {
  return (
    <>
      {
        items?.map((item: SortedItems) => {
          if (!item.groups.length && !item.lines.length) {
            return <></>;
          }
          return (
            <>
              <H3Tag>{item.date}</H3Tag>
              <MonitoringViewItemsList
                items={item.groups}
                type={'group'}
              />
              <MonitoringViewItemsList
                items={item.lines}
                type={'line'}
              />
            </>
          );
        })
      }
    </>
  );
};